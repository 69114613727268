import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import { Loader, ButtonModifier } from "../../../components";
import { Container, Form, Row, Col } from "react-bootstrap";
import { Box, Button } from "@mui/material";

const TampilTransferStok = () => {
  const { user } = useContext(AuthContext);
  const { id, idTransferStok } = useParams();
  const { screenSize } = useStateContext();

  const [isFetchError, setIsFetchError] = useState(false);
  const [tanggalTransferStok, setTanggalTransferStok] = useState("");
  const [kodeBarang, setKodeBarang] = useState("");
  const [namaBarang, setNamaBarang] = useState("");
  const [kuantitasStok, setKuantitasStok] = useState("");
  const [jenisTransfer, setJenisTransfer] = useState("");
  const [keterangan, setKeterangan] = useState("");

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    idTransferStok && getTransferStokById();
  }, [idTransferStok]);

  const getTransferStokById = async () => {
    if (idTransferStok) {
      setLoading(true);
      const response = await axios.post(
        `${tempUrl}/transferStoks/${idTransferStok}`,
        {
          _id: user.id,
          token: user.token,
          kodeCabang: user.cabang.id,
        }
      );
      setTanggalTransferStok(response.data.tanggalTransferStokFormatted);
      setKodeBarang(response.data.barang.kodeBarang);
      setNamaBarang(response.data.namaBarang);
      setKuantitasStok(response.data.kuantitasStok);
      setJenisTransfer(response.data.jenisTransfer);
      setKeterangan(response.data.keterangan);
      setLoading(false);
    }
  };

  const deleteTransferStokTokoGudang = async (idTransferStok) => {
    setLoading(true);
    try {
      await axios.post(
        `${tempUrl}/deleteTransferStokTokoGudang/${idTransferStok}`,
        {
          transferStokHeaderId: id,
          _id: user.id,
          token: user.token,
          kodeCabang: user.cabang.id,
        }
      );
      setTanggalTransferStok("");
      setKodeBarang("");
      setNamaBarang("");
      setKuantitasStok("");
      setJenisTransfer("");
      setKeterangan("");
      navigate(`/daftarTransferStok/transferStok/${id}`);
    } catch (error) {
      if (error.response.data.message.includes("foreign key")) {
        alert(`${kuantitasStok} tidak bisa dihapus karena sudah ada data!`);
      }
    }
    setLoading(false);
  };

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Container>
      <h3>Stok</h3>
      <h5 style={{ fontWeight: 400 }}>Transfer Stok</h5>
      <Box sx={spacingTop}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => navigate(`/daftarTransferStok/transferStok/${id}`)}
          sx={{ marginRight: 2 }}
        >
          {"< Kembali"}
        </Button>
      </Box>
      <Box sx={buttonModifierContainer}>
        <ButtonModifier
          id={idTransferStok}
          kode={idTransferStok}
          addLink={null}
          editLink={`/daftarTransferStok/transferStok/${id}/${idTransferStok}/edit`}
          deleteUser={deleteTransferStokTokoGudang}
          nameUser={kuantitasStok}
        />
      </Box>
      {id && (
        <Container>
          <hr />
          <Form>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Tgl. Transfer :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      value={tanggalTransferStok}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Kode Barang :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={kodeBarang} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Nama Barang :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={namaBarang} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Kuantitas :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={kuantitasStok} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Jenis Transfer :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={jenisTransfer} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Keterangan :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={keterangan}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Container>
      )}
      <hr />
    </Container>
  );
};

export default TampilTransferStok;

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const spacingTop = {
  mt: 4,
};
