import { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../../contexts/ContextProvider";
import { Loader } from "../../../../components";
import { ShowTableJualChild } from "../../../../components/ShowTable";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Alert,
  Button,
  Snackbar,
  Typography,
  Autocomplete,
  TextField,
  Pagination,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { formatDate } from "../../../../constants/helper";

const TambahJualChild = () => {
  const { screenSize } = useStateContext();
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [validated, setValidated] = useState(false);
  // Data Jual
  const [noNotaJual, setNoNotaJual] = useState("");
  const [tanggalJual, setTanggalJual] = useState("");
  const [tanggalJualDate, setTanggalJualDate] = useState("");
  // Data Barang
  const [kodeBarang, setKodeBarang] = useState("");
  const [namaBarang, setNamaBarang] = useState("");
  const [jumlah1, setJumlah1] = useState("0");
  const [jumlah2, setJumlah2] = useState("0");
  const [satuan1, setSatuan1] = useState("");
  const [satuan2, setSatuan2] = useState("");

  const [harga, setHarga] = useState("0");
  const [subtotalJualChild, setSubtotalJualChild] = useState("");

  const [barangs, setBarangs] = useState([]);
  const [jualsChildPagination, setJualsChildPagination] = useState([]);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");
  const PER_PAGE = 20;

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  let barangOptions = barangs.map((barang) => ({
    label: `${barang.kodeBarang} - ${barang.namaBarang}`,
  }));

  useEffect(() => {
    getBarangsData();
    getJual();
  }, []);

  const getBarangsData = async (kodeUnit) => {
    const response = await axios.post(`${tempUrl}/barangs`, {
      _id: user.id,
      token: user.token,
    });
    setBarangs(response.data);
  };

  const getJual = async () => {
    setLoading(true);
    const pickedJual = await axios.post(`${tempUrl}/juals/${id}`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setNoNotaJual(pickedJual.data.noNotaJual);
    setTanggalJual(formatDate(pickedJual.data.tanggalJual));
    setTanggalJualDate(pickedJual.data.tanggalJual);

    const response2 = await axios.post(
      `${tempUrl}/jualChildsByJualPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
      {
        jualId: pickedJual.data.id,
        kodeCabang: user.cabang.id,
        _id: user.id,
        token: user.token,
      }
    );
    setJualsChildPagination(response2.data.juals);
    setLoading(false);
  };

  const getBarang = async (kodeBarang) => {
    if (kodeBarang.length === 0) {
      return;
    }
    let tempKodeBarang = kodeBarang.split(" -", 1)[0];
    const pickedBarang = await axios.post(`${tempUrl}/barangsByKodeBarang`, {
      kodeBarang: tempKodeBarang,
      _id: user.id,
      token: user.token,
    });
    if (pickedBarang.data) {
      setKodeBarang(pickedBarang.data.kodeBarang);
      setNamaBarang(pickedBarang.data.namaBarang);
      setSatuan1(pickedBarang.data.satuan1.toLocaleString("en-US"));
      setSatuan1(pickedBarang.data.satuan1);
      setSatuan1(pickedBarang.data.satuan1.toLocaleString("en-US"));
      setSatuan2(pickedBarang.data.satuan2);
    }

    setKodeBarang(kodeBarang);
  };

  const saveJualChild = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() || kodeBarang.length !== 0) {
      setLoading(true);
      try {
        setLoading(true);
        await axios.post(`${tempUrl}/saveJualChild`, {
          // Data Jual
          noNotaJual,
          tanggalJual: tanggalJualDate,
          jualId: id,

          // Data Barang
          kodeBarang: kodeBarang.split(" -", 1)[0],
          namaBarang: namaBarang.replace(/,/g, ""),
          jumlah1: jumlah1.replace(/,/g, ""),
          jumlah2: jumlah2.replace(/,/g, ""),
          satuan1: satuan1,
          satuan2: satuan2,
          harga: harga.replace(/,/g, ""),
          subtotalJualChild: subtotalJualChild.replace(/,/g, ""),

          userIdInput: user.id,
          kodeCabang: user.cabang.id,
          _id: user.id,
          token: user.token,
        });
        setKodeBarang("");
        setNamaBarang("");
        setJumlah1("0");
        setJumlah2("0");
        setSatuan1("");
        setSatuan2("");
        setHarga("");
        setSubtotalJualChild("");

        const response2 = await axios.post(
          `${tempUrl}/jualChildsByJualPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
          {
            jualId: id,
            kodeCabang: user.cabang.id,
            _id: user.id,
            token: user.token,
          }
        );
        setJualsChildPagination(response2.data.juals);

        setLoading(false);
        setSuccess(true);
        setOpenSuccess(!openSuccess);
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  const textRightSmall = {
    textAlign: screenSize >= 650 && "right",
    fontSize: "14px",
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Transaksi</h3>
      <h5 style={{ fontWeight: 400 }}>Tambah Detail Jual</h5>
      <hr />
      <Card>
        <Card.Header>Detail Jual</Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={saveJualChild}>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        No. Nota :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          required
                          value={noNotaJual}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Tanggal :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          required
                          value={tanggalJual}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Barang <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="9">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={barangOptions}
                          renderInput={(params) => (
                            <TextField
                              error={error && kodeBarang.length === 0 && true}
                              size="small"
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => {
                            getBarang(value);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRightSmall}>
                        Nama <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          required
                          value={namaBarang}
                          onChange={(e) =>
                            setNamaBarang(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="6" style={textRightSmall}>
                        Jumlah 1 :
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          value={jumlah1}
                          onChange={(e) => {
                            let tempNum;
                            let isNumNan = isNaN(
                              parseInt(e.target.value.replace(/,/g, ""), 10)
                            );
                            if (isNumNan) {
                              tempNum = "";
                            } else {
                              tempNum = parseInt(
                                e.target.value.replace(/,/g, ""),
                                10
                              ).toLocaleString("en-US");
                            }
                            setJumlah1(tempNum);
                            setSubtotalJualChild(
                              (
                                tempNum.replace(/,/g, "") *
                                harga.replace(/,/g, "")
                              ).toLocaleString("en-US")
                            );
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="6" style={textRightSmall}>
                        Jumlah 2 :
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          value={jumlah2}
                          onChange={(e) => {
                            let tempNum;
                            let isNumNan = isNaN(
                              parseInt(e.target.value.replace(/,/g, ""), 10)
                            );
                            if (isNumNan) {
                              tempNum = "";
                            } else {
                              tempNum = parseInt(
                                e.target.value.replace(/,/g, ""),
                                10
                              ).toLocaleString("en-US");
                            }
                            setJumlah2(tempNum);
                            setSubtotalJualChild(
                              (
                                tempNum.replace(/,/g, "") *
                                harga.replace(/,/g, "")
                              ).toLocaleString("en-US")
                            );
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="6" style={textRightSmall}>
                        Satuan 1 :
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control value={satuan1} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="6" style={textRightSmall}>
                        Satuan 2 :
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control value={satuan2} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRightSmall}>
                        Harga <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          required
                          value={harga}
                          onChange={(e) => {
                            let tempNum;
                            let isNumNan = isNaN(
                              parseInt(e.target.value.replace(/,/g, ""), 10)
                            );
                            if (isNumNan) {
                              tempNum = "";
                            } else {
                              tempNum = parseInt(
                                e.target.value.replace(/,/g, ""),
                                10
                              ).toLocaleString("en-US");
                            }
                            setHarga(tempNum);
                            setSubtotalJualChild(
                              (
                                tempNum.replace(/,/g, "") *
                                jumlah1.replace(/,/g, "")
                              ).toLocaleString("en-US")
                            );
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Subtotal <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          required
                          value={subtotalJualChild}
                          onChange={(e) => {
                            let tempNum;
                            let isNumNan = isNaN(
                              parseInt(e.target.value.replace(/,/g, ""), 10)
                            );
                            if (isNumNan) {
                              tempNum = "";
                            } else {
                              tempNum = parseInt(
                                e.target.value.replace(/,/g, ""),
                                10
                              ).toLocaleString("en-US");
                            }
                            setSubtotalJualChild(tempNum);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
            <Box sx={spacingTop}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate(`/daftarJual/jual/${id}`)}
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button
                variant="outlined"
                color="success"
                onClick={() => navigate(`/daftarJual/jual/${id}/tambahBarang`)}
                sx={{ marginRight: 2 }}
              >
                {"Tambah Barang"}
              </Button>
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                type="submit"
              >
                Simpan
              </Button>
            </Box>
          </Form>
        </Card.Body>
      </Card>
      <Box sx={tableContainer}>
        <ShowTableJualChild id={id} currentPosts={jualsChildPagination} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
      {success && (
        <Snackbar
          open={openSuccess}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleCloseSuccess} severity="success" sx={alertBox}>
            Data berhasil ditambahkan!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default TambahJualChild;

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const subTitleText = {
  fontWeight: "900",
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const colorRed = {
  color: "red",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};
