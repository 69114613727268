import React, { useContext, useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "./styles.scss";
import { Sidebar, Footer, ScrollToTop } from "./components";
import { AuthContext } from "./contexts/AuthContext";
import { useStateContext } from "./contexts/ContextProvider";
import {
  Login,
  ProfilUser,
  UbahProfilUser,
  DaftarUser,
  TambahUser,
  UbahUser,
  TampilSetting,
  UbahSetting,
  TampilKategori,
  UbahKategori,
  TambahKategori,
  TampilBarang,
  UbahBarang,
  TambahBarang,
  TampilSupplier,
  UbahSupplier,
  TambahSupplier,
  TampilPelanggan,
  UbahPelanggan,
  TambahPelanggan,
  TampilCabang,
  UbahCabang,
  TambahCabang,
  TampilDaftarStokGudang,
  UbahBarangFromStokGudang,
  TampilDaftarStokToko,
  TampilTransferStok,
  UbahTransferStok,
  TampilDaftarTransferStok,
  TambahTransferStokHeader,
  TampilTransferStokHeader,
  TambahTransferStok,
  TampilDaftarTransferStokBarang,
  TampilKoreksiStokGudang,
  TambahKoreksiStokGudang,
  TampilKoreksiStokToko,
  TambahKoreksiStokToko,
  TampilDaftarBeli,
  TambahSupplierFromBeli,
  TambahBeli,
  TampilBeli,
  UbahBeli,
  TambahBeliChild,
  UbahBeliChild,
  TambahBarangFromBeliChild,
  TampilBeliChild,
  TampilPembayaranHutang,
  TambahPembayaranHutang,
  TampilDaftarJual,
  TambahPelangganFromJual,
  TambahJual,
  TampilJual,
  UbahJual,
  TambahJualChild,
  TampilJualChild,
  TampilPembayaranPiutang,
  TambahPembayaranPiutang,
  LaporanStokBarang,
  LaporanPembelianBarang,
  LaporanPembelian,
  LaporanPenjualan,
  LaporanPenjualanRinci,
  LaporanLaba,
  LaporanKeuangan,
} from "./pages/index";
import { FaBars } from "react-icons/fa";

const App = () => {
  const { screenSize, setScreenSize } = useStateContext();
  const { user } = useContext(AuthContext);
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);

  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  const USERRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user) {
      return children;
    }

    return <Navigate to="/login" />;
  };

  const PROFILUSERRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.profilUser) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const DAFTARUSERRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.daftarUser) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const SETTINGRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.setting) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const KATEGORIRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.kategori) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const BARANGRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.barang) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const SUPPLIERRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.supplier) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const PELANGGANRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.pelanggan) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const CABANGRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.cabang) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const STOKGUDANGRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.stokGudang) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const STOKTOKORoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.stokToko) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const KOREKSISTOKGUDANGRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.koreksiStokGudang) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const KOREKSISTOKTOKORoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.koreksiStokToko) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const TRANSFERSTOKRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.transferStok) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const PEMBELIANRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.pembelian) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const PEMBAYARANHUTANGRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.pembayaranHutang) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const PENJUALANRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.penjualan) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const PEMBAYARANPIUTANGRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.pembayaranPiutang) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const LAPORANSTOKBARANGRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.laporanStokBarang) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const LAPORANPEMBELIANBARANGRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.laporanPembelianBarang) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const LAPORANPEMBELIANRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.laporanPembelian) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const LAPORANPENJUALANRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.laporanPenjualan) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const LAPORANLABARoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.laporanLaba) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const LAPORANKEUANGANRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.laporanKeuangan) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={`app ${toggled ? "toggled" : ""}`}>
      {user && (
        <Sidebar
          collapsed={collapsed}
          toggled={toggled}
          handleToggleSidebar={handleToggleSidebar}
          handleCollapsedChange={handleCollapsedChange}
        />
      )}

      <main>
        <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
          <FaBars />
        </div>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          {/* Profil User */}
          <Route
            path="/profilUser"
            element={
              <PROFILUSERRoute>
                <ProfilUser />
              </PROFILUSERRoute>
            }
          />
          <Route
            path="/profilUser/:id/edit"
            element={
              <PROFILUSERRoute>
                <UbahProfilUser />
              </PROFILUSERRoute>
            }
          />
          {/* Daftar User */}
          <Route
            path="/daftarUser"
            element={
              <DAFTARUSERRoute>
                <DaftarUser />
              </DAFTARUSERRoute>
            }
          />
          <Route
            path="/daftarUser/:id"
            element={
              <DAFTARUSERRoute>
                <DaftarUser />
              </DAFTARUSERRoute>
            }
          />
          <Route
            path="/daftarUser/:id/edit"
            element={
              <DAFTARUSERRoute>
                <UbahUser />
              </DAFTARUSERRoute>
            }
          />
          <Route
            path="/daftarUser/tambahUser"
            element={
              <DAFTARUSERRoute>
                <TambahUser />
              </DAFTARUSERRoute>
            }
          />
          {/* Setting */}
          <Route
            path="/setting"
            element={
              <SETTINGRoute>
                <TampilSetting />
              </SETTINGRoute>
            }
          />
          <Route
            path="/setting/:id/edit"
            element={
              <SETTINGRoute>
                <UbahSetting />
              </SETTINGRoute>
            }
          />
          {/* Kategori */}
          <Route
            path="/kategori"
            element={
              <KATEGORIRoute>
                <TampilKategori />
              </KATEGORIRoute>
            }
          />
          <Route
            path="/kategori/:id"
            element={
              <KATEGORIRoute>
                <TampilKategori />
              </KATEGORIRoute>
            }
          />
          <Route
            path="/kategori/:id/edit"
            element={
              <KATEGORIRoute>
                <UbahKategori />
              </KATEGORIRoute>
            }
          />
          <Route
            path="/kategori/tambahKategori"
            element={
              <KATEGORIRoute>
                <TambahKategori />
              </KATEGORIRoute>
            }
          />
          {/* Barang */}
          <Route
            path="/barang"
            element={
              <BARANGRoute>
                <TampilBarang />
              </BARANGRoute>
            }
          />
          <Route
            path="/barang/:id"
            element={
              <BARANGRoute>
                <TampilBarang />
              </BARANGRoute>
            }
          />
          <Route
            path="/barang/:id/edit"
            element={
              <BARANGRoute>
                <UbahBarang />
              </BARANGRoute>
            }
          />
          <Route
            path="/barang/tambahBarang"
            element={
              <BARANGRoute>
                <TambahBarang />
              </BARANGRoute>
            }
          />
          {/* Supplier */}
          <Route
            path="/supplier"
            element={
              <SUPPLIERRoute>
                <TampilSupplier />
              </SUPPLIERRoute>
            }
          />
          <Route
            path="/supplier/:id"
            element={
              <SUPPLIERRoute>
                <TampilSupplier />
              </SUPPLIERRoute>
            }
          />
          <Route
            path="/supplier/:id/edit"
            element={
              <SUPPLIERRoute>
                <UbahSupplier />
              </SUPPLIERRoute>
            }
          />
          <Route
            path="/supplier/tambahSupplier"
            element={
              <SUPPLIERRoute>
                <TambahSupplier />
              </SUPPLIERRoute>
            }
          />
          {/* Pelanggan */}
          <Route
            path="/pelanggan"
            element={
              <PELANGGANRoute>
                <TampilPelanggan />
              </PELANGGANRoute>
            }
          />
          <Route
            path="/pelanggan/:id"
            element={
              <PELANGGANRoute>
                <TampilPelanggan />
              </PELANGGANRoute>
            }
          />
          <Route
            path="/pelanggan/:id/edit"
            element={
              <PELANGGANRoute>
                <UbahPelanggan />
              </PELANGGANRoute>
            }
          />
          <Route
            path="/pelanggan/tambahPelanggan"
            element={
              <PELANGGANRoute>
                <TambahPelanggan />
              </PELANGGANRoute>
            }
          />
          {/* Cabang */}
          <Route
            path="/cabang"
            element={
              <CABANGRoute>
                <TampilCabang />
              </CABANGRoute>
            }
          />
          <Route
            path="/cabang/:id"
            element={
              <CABANGRoute>
                <TampilCabang />
              </CABANGRoute>
            }
          />
          <Route
            path="/cabang/:id/edit"
            element={
              <CABANGRoute>
                <UbahCabang />
              </CABANGRoute>
            }
          />
          <Route
            path="/cabang/tambahCabang"
            element={
              <CABANGRoute>
                <TambahCabang />
              </CABANGRoute>
            }
          />
          {/* Daftar Stok Gudang */}
          <Route
            path="/daftarStokGudang"
            element={
              <STOKGUDANGRoute>
                <TampilDaftarStokGudang />
              </STOKGUDANGRoute>
            }
          />
          <Route
            path="/daftarStokGudang/:id"
            element={
              <STOKGUDANGRoute>
                <TampilDaftarStokGudang />
              </STOKGUDANGRoute>
            }
          />
          <Route
            path="/daftarStokGudang/:id/edit"
            element={
              <STOKGUDANGRoute>
                <UbahBarangFromStokGudang />
              </STOKGUDANGRoute>
            }
          />
          {/* Daftar Stok Toko */}
          <Route
            path="/daftarStokToko"
            element={
              <STOKTOKORoute>
                <TampilDaftarStokToko />
              </STOKTOKORoute>
            }
          />
          <Route
            path="/daftarStokToko/:id"
            element={
              <STOKTOKORoute>
                <TampilDaftarStokToko />
              </STOKTOKORoute>
            }
          />
          {/* Transfer Stok */}
          <Route
            path="/daftarTransferStok"
            element={
              <TRANSFERSTOKRoute>
                <TampilDaftarTransferStok />
              </TRANSFERSTOKRoute>
            }
          />
          <Route
            path="/daftarTransferStok/transferStok/tambahTransferStok"
            element={
              <TRANSFERSTOKRoute>
                <TambahTransferStokHeader />
              </TRANSFERSTOKRoute>
            }
          />
          <Route
            path="/daftarTransferStok/transferStok/:id"
            element={
              <TRANSFERSTOKRoute>
                <TampilTransferStokHeader />
              </TRANSFERSTOKRoute>
            }
          />
          <Route
            path="/daftarTransferStok/transferStok/:id/tambahTransferStok"
            element={
              <TRANSFERSTOKRoute>
                <TambahTransferStok />
              </TRANSFERSTOKRoute>
            }
          />
          <Route
            path="/daftarTransferStok/transferStok/:id/:idTransferStok"
            element={
              <TRANSFERSTOKRoute>
                <TampilTransferStok />
              </TRANSFERSTOKRoute>
            }
          />
          <Route
            path="/daftarTransferStok/transferStok/:id/:idTransferStok/edit"
            element={
              <TRANSFERSTOKRoute>
                <UbahTransferStok />
              </TRANSFERSTOKRoute>
            }
          />
          {/* Transfer Stok Barang */}
          <Route
            path="/daftarTransferStokBarang"
            element={
              <TRANSFERSTOKRoute>
                <TampilDaftarTransferStokBarang />
              </TRANSFERSTOKRoute>
            }
          />
          <Route
            path="/daftarTransferStokBarang/:id"
            element={
              <TRANSFERSTOKRoute>
                <TampilDaftarTransferStokBarang />
              </TRANSFERSTOKRoute>
            }
          />
          {/* Koreksi Stok Gudang */}
          <Route
            path="/koreksiStokGudang"
            element={
              <KOREKSISTOKGUDANGRoute>
                <TampilKoreksiStokGudang />
              </KOREKSISTOKGUDANGRoute>
            }
          />
          <Route
            path="/koreksiStokGudang/:id"
            element={
              <KOREKSISTOKGUDANGRoute>
                <TampilKoreksiStokGudang />
              </KOREKSISTOKGUDANGRoute>
            }
          />
          <Route
            path="/koreksiStokGudang/tambahKoreksiStokGudang"
            element={
              <KOREKSISTOKGUDANGRoute>
                <TambahKoreksiStokGudang />
              </KOREKSISTOKGUDANGRoute>
            }
          />
          {/* Koreksi Stok Toko */}
          <Route
            path="/koreksiStokToko"
            element={
              <KOREKSISTOKTOKORoute>
                <TampilKoreksiStokToko />
              </KOREKSISTOKTOKORoute>
            }
          />
          <Route
            path="/koreksiStokToko/:id"
            element={
              <KOREKSISTOKTOKORoute>
                <TampilKoreksiStokToko />
              </KOREKSISTOKTOKORoute>
            }
          />
          <Route
            path="/koreksiStokToko/tambahKoreksiStokToko"
            element={
              <KOREKSISTOKTOKORoute>
                <TambahKoreksiStokToko />
              </KOREKSISTOKTOKORoute>
            }
          />
          {/* TRANSAKSI */}
          {/* Pembelian */}
          <Route
            path="/daftarBeli"
            element={
              <PEMBELIANRoute>
                <TampilDaftarBeli />
              </PEMBELIANRoute>
            }
          />
          <Route
            path="/daftarBeli/beli/tambahSupplier"
            element={
              <PEMBELIANRoute>
                <TambahSupplierFromBeli />
              </PEMBELIANRoute>
            }
          />
          <Route
            path="/daftarBeli/beli/tambahBeli"
            element={
              <PEMBELIANRoute>
                <TambahBeli />
              </PEMBELIANRoute>
            }
          />
          <Route
            path="/daftarBeli/beli/:id"
            element={
              <PEMBELIANRoute>
                <TampilBeli />
              </PEMBELIANRoute>
            }
          />
          <Route
            path="/daftarBeli/beli/:id/edit"
            element={
              <PEMBELIANRoute>
                <UbahBeli />
              </PEMBELIANRoute>
            }
          />
          <Route
            path="/daftarBeli/beli/:id/tambahBeliChild"
            element={
              <PEMBELIANRoute>
                <TambahBeliChild />
              </PEMBELIANRoute>
            }
          />
          <Route
            path="/daftarBeli/beli/:id/tambahBarang"
            element={
              <PEMBELIANRoute>
                <TambahBarangFromBeliChild />
              </PEMBELIANRoute>
            }
          />
          <Route
            path="/daftarBeli/beli/:id/:idBeliChild"
            element={
              <PEMBELIANRoute>
                <TampilBeliChild />
              </PEMBELIANRoute>
            }
          />
          <Route
            path="/daftarBeli/beli/:id/:idBeliChild/edit"
            element={
              <PEMBELIANRoute>
                <UbahBeliChild />
              </PEMBELIANRoute>
            }
          />
          {/* Pembayaran Hutang */}
          <Route
            path="/pembayaranHutang"
            element={
              <PEMBAYARANHUTANGRoute>
                <TampilPembayaranHutang />
              </PEMBAYARANHUTANGRoute>
            }
          />
          <Route
            path="/pembayaranHutang/:id"
            element={
              <PEMBAYARANHUTANGRoute>
                <TampilPembayaranHutang />
              </PEMBAYARANHUTANGRoute>
            }
          />
          <Route
            path="/pembayaranHutang/tambahPembayaranHutang"
            element={
              <PEMBAYARANHUTANGRoute>
                <TambahPembayaranHutang />
              </PEMBAYARANHUTANGRoute>
            }
          />
          {/* Penjualan */}
          <Route
            path="/daftarJual"
            element={
              <PENJUALANRoute>
                <TampilDaftarJual />
              </PENJUALANRoute>
            }
          />
          <Route
            path="/daftarJual/jual/tambahPelanggan"
            element={
              <PENJUALANRoute>
                <TambahPelangganFromJual />
              </PENJUALANRoute>
            }
          />
          <Route
            path="/daftarJual/jual/tambahJual"
            element={
              <PENJUALANRoute>
                <TambahJual />
              </PENJUALANRoute>
            }
          />
          <Route
            path="/daftarJual/jual/:id"
            element={
              <PENJUALANRoute>
                <TampilJual />
              </PENJUALANRoute>
            }
          />
          <Route
            path="/daftarJual/jual/:id/edit"
            element={
              <PENJUALANRoute>
                <UbahJual />
              </PENJUALANRoute>
            }
          />
          <Route
            path="/daftarJual/jual/:id/tambahJualChild"
            element={
              <PENJUALANRoute>
                <TambahJualChild />
              </PENJUALANRoute>
            }
          />
          {/* <Route
            path="/daftarJual/jual/:id/tambahBarang"
            element={
              <PENJUALANRoute>
                <TambahBarangFromJualChild />
              </PENJUALANRoute>
            }
          /> */}
          <Route
            path="/daftarJual/jual/:id/:idJualChild"
            element={
              <PENJUALANRoute>
                <TampilJualChild />
              </PENJUALANRoute>
            }
          />
          {/* Pembayaran Piutang */}
          <Route
            path="/pembayaranPiutang"
            element={
              <PEMBAYARANPIUTANGRoute>
                <TampilPembayaranPiutang />
              </PEMBAYARANPIUTANGRoute>
            }
          />
          <Route
            path="/pembayaranPiutang/:id"
            element={
              <PEMBAYARANPIUTANGRoute>
                <TampilPembayaranPiutang />
              </PEMBAYARANPIUTANGRoute>
            }
          />
          <Route
            path="/pembayaranPiutang/tambahPembayaranPiutang"
            element={
              <PEMBAYARANPIUTANGRoute>
                <TambahPembayaranPiutang />
              </PEMBAYARANPIUTANGRoute>
            }
          />
          {/* Laporan */}
          <Route
            path="/laporanStokBarang"
            element={
              <LAPORANSTOKBARANGRoute>
                <LaporanStokBarang />
              </LAPORANSTOKBARANGRoute>
            }
          />
          <Route
            path="/laporanPembelianBarang"
            element={
              <LAPORANPEMBELIANBARANGRoute>
                <LaporanPembelianBarang />
              </LAPORANPEMBELIANBARANGRoute>
            }
          />
          <Route
            path="/laporanPembelian"
            element={
              <LAPORANPEMBELIANRoute>
                <LaporanPembelian />
              </LAPORANPEMBELIANRoute>
            }
          />
          <Route
            path="/laporanPenjualan"
            element={
              <LAPORANPENJUALANRoute>
                <LaporanPenjualan />
              </LAPORANPENJUALANRoute>
            }
          />
          <Route
            path="/laporanPenjualanRinci"
            element={
              <LAPORANPENJUALANRoute>
                <LaporanPenjualanRinci />
              </LAPORANPENJUALANRoute>
            }
          />
          <Route
            path="/laporanLaba"
            element={
              <LAPORANLABARoute>
                <LaporanLaba />
              </LAPORANLABARoute>
            }
          />
          <Route
            path="/laporanKeuangan"
            element={
              <LAPORANKEUANGANRoute>
                <LaporanKeuangan />
              </LAPORANKEUANGANRoute>
            }
          />
        </Routes>
        <Footer />
      </main>
    </div>
  );
};

export default App;
