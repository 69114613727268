import { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../../contexts/ContextProvider";
import { Loader, ButtonModifier } from "../../../../components";
import { ShowTableJualChild } from "../../../../components/ShowTable";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import { Box, Button, Pagination, Typography } from "@mui/material";

const TampilJual = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch, setting } = useContext(AuthContext);
  const [noNotaJual, setNoNotaJual] = useState("");
  const [jenisJual, setJenisJual] = useState("");
  const [tanggalJual, setTanggalJual] = useState("");
  const [tanggalJualDate, setTanggalJualDate] = useState();
  const [totalJual, setTotalJual] = useState("");
  const [pelanggan, setPelanggan] = useState("");

  const [jualsChildPagination, setJualsChildPagination] = useState([]);
  const navigate = useNavigate();
  let today = new Date();
  let isEditableManager = user.tipeUser !== "ADMIN";
  let isEditableAdmin =
    user.tipeUser === "ADMIN" &&
    tanggalJualDate?.getFullYear() === today.getFullYear() &&
    tanggalJualDate?.getMonth() === today.getMonth() &&
    tanggalJualDate?.getDate() === today.getDate();
  let isEditable = isEditableManager || isEditableAdmin;

  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");
  const PER_PAGE = 20;

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  useEffect(() => {
    getJualById();
  }, []);

  const getJualById = async () => {
    setLoading(true);
    const response = await axios.post(`${tempUrl}/juals/${id}`, {
      _id: user.id,
      token: user.token,
    });
    setNoNotaJual(response.data.noNotaJual);
    setJenisJual(response.data.jenisJual);
    setTanggalJual(response.data.tanggalJualFormatted);
    setTanggalJualDate(new Date(response.data.tanggalJual));
    setTotalJual(response.data.totalJual);
    setPelanggan(response.data.pelanggan);

    const response2 = await axios.post(
      `${tempUrl}/jualChildsByJualPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
      {
        jualId: response.data.id,
        kodeCabang: user.cabang.id,
        _id: user.id,
        token: user.token,
      }
    );
    setJualsChildPagination(response2.data.juals);
    setLoading(false);
  };

  const deleteJual = async (id) => {
    setLoading(true);
    try {
      await axios.post(`${tempUrl}/deleteJual/${id}`, {
        _id: user.id,
        token: user.token,
      });
      navigate("/daftarJual");
    } catch (error) {
      alert(error.response.data.message);
    }
    setLoading(false);
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Penjualan</h3>
      <h5 style={{ fontWeight: 400 }}>Jual</h5>
      <hr />
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => navigate("/daftarJual")}
        sx={{ marginLeft: 2, marginTop: 4 }}
      >
        {"< Kembali"}
      </Button>
      <Box sx={buttonModifierContainer}>
        {isEditable && (
          <>
            <ButtonModifier
              id={id}
              kode={"test"}
              addLink={`/daftarJual/jual/${id}/tambahJualChild`}
              editLink={`/daftarJual/jual/${id}/edit`}
              deleteUser={deleteJual}
              nameUser={noNotaJual}
            />
          </>
        )}
      </Box>
      <Form>
        <Card>
          <Card.Header>Jual</Card.Header>
          <Card.Body>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No. Jual :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={noNotaJual}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Jenis Jual :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={jenisJual}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Jual :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={tanggalJual}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Pelanggan :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={`${pelanggan.kodePelanggan} - ${pelanggan.namaPelanggan}`}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Total :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={totalJual.toLocaleString("en-US")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Form>
      <Box sx={tableContainer}>
        <ShowTableJualChild id={id} currentPosts={jualsChildPagination} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Container>
  );
};

export default TampilJual;

const buttonModifierContainer = {
  mt: 4,
  mb: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const subTitleText = {
  fontWeight: "900",
};
