import { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../../constants/helper";
import { AuthContext } from "../../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../../contexts/ContextProvider";
import { Loader } from "../../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  ButtonGroup,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";

const TampilJualChild = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch } = useContext(AuthContext);
  const { id, idJualChild } = useParams();
  const [tempIdJualChild, setTempIdJualChild] = useState("");
  // Data Jual
  const [noNotaJual, setNoNotaJual] = useState("");
  const [tanggalJual, setTanggalJual] = useState("");
  const [tanggalJualDate, setTanggalJualDate] = useState();
  // Data Barang
  const [kodeBarang, setKodeBarang] = useState("");
  const [namaBarang, setNamaBarang] = useState("");
  const [jumlah1, setJumlah1] = useState("0");
  const [jumlah2, setJumlah2] = useState("0");
  const [satuan1, setSatuan1] = useState("");
  const [satuan2, setSatuan2] = useState("");
  const [harga, setHarga] = useState("");
  const [subtotalJualChild, setSubtotalJualChild] = useState("");

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  let today = new Date();
  let isEditableManager = user.tipeUser !== "ADMIN";
  let isEditableAdmin =
    user.tipeUser === "ADMIN" &&
    tanggalJualDate?.getFullYear() === today.getFullYear() &&
    tanggalJualDate?.getMonth() === today.getMonth() &&
    tanggalJualDate?.getDate() === today.getDate();
  let isEditable = isEditableManager || isEditableAdmin;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getJualChildById();
  }, []);

  const getJualChildById = async () => {
    if (id) {
      const response = await axios.post(
        `${tempUrl}/jualChilds/${idJualChild}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      // Data Jual
      setTempIdJualChild(response.data.id);
      setNoNotaJual(response.data.noNotaJual);
      setTanggalJual(formatDate(response.data.tanggalJual));
      setTanggalJualDate(response.data.tanggalJual);

      // Data Barang
      setKodeBarang(
        `${response.data.barang.kodeBarang} - ${response.data.barang.namaBarang}`
      );
      setNamaBarang(response.data.namaBarang);
      setJumlah1(response.data.jumlah1.toLocaleString("en-US"));
      setJumlah2(response.data.jumlah2.toLocaleString("en-US"));
      setSatuan1(response.data.satuan1);
      setSatuan2(response.data.satuan2);
      setHarga(response.data.harga.toLocaleString("en-US"));
      setSubtotalJualChild(
        response.data.subtotalJualChild.toLocaleString("en-US")
      );
    }
  };

  const deleteJualChild = async (id) => {
    try {
      setLoading(true);
      // Delete Jual Child
      await axios.post(`${tempUrl}/deleteJualChild/${tempIdJualChild}`, {
        jualId: id,
        kodeBarang: kodeBarang.split(" -", 1)[0],
        jumlah1: jumlah1.replace(/,/g, ""),
        subtotalJualChild: subtotalJualChild.replace(/,/g, ""),
        _id: user.id,
        token: user.token,
      });
      setLoading(false);
      navigate(`/daftarJual/jual/${id}`);
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  const textRightSmall = {
    textAlign: screenSize >= 650 && "right",
    fontSize: "14px",
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Penjualan</h3>
      <h5 style={{ fontWeight: 400 }}>Detail Jual</h5>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => navigate(`/daftarJual/jual/${id}`)}
        sx={{ marginLeft: 2, marginTop: 4 }}
      >
        {"< Kembali"}
      </Button>
      <Box sx={deleteButtonContainer}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{`Hapus Data`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {`Yakin ingin menghapus data ${namaBarang}?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => deleteJualChild(id)}>Ok</Button>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
        {isEditable && (
          <ButtonGroup variant="contained">
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteOutlineIcon />}
              sx={{ textTransform: "none" }}
              onClick={handleClickOpen}
            >
              Hapus
            </Button>
          </ButtonGroup>
        )}
      </Box>
      <hr />
      <Card>
        <Card.Header>Detail Jual</Card.Header>
        <Card.Body>
          <Form>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        No. Nota :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          required
                          value={noNotaJual}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Tanggal :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          required
                          value={tanggalJual}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Barang :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          required
                          value={kodeBarang}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRightSmall}>
                        Nama <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          required
                          value={namaBarang}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="6" style={textRightSmall}>
                        Jumlah 1 :
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          required
                          value={jumlah1}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="6" style={textRightSmall}>
                        Jumlah 2 :
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          required
                          value={jumlah2}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="6" style={textRightSmall}>
                        Satuan 1 :
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          required
                          value={satuan1}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="6" style={textRightSmall}>
                        Satuan 2 :
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          required
                          value={satuan2}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRightSmall}>
                        Harga :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          required
                          value={harga}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Subtotal :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          required
                          value={subtotalJualChild}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default TampilJualChild;

const deleteButtonContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const colorRed = {
  color: "red",
};
