import { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../../contexts/ContextProvider";
import { Loader, ButtonModifier } from "../../../../components";
import { ShowTableBeliChild } from "../../../../components/ShowTable";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import { Box, Button, Pagination, Typography } from "@mui/material";

const TampilBeli = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch, setting } = useContext(AuthContext);
  const [noNotaBeli, setNoNotaBeli] = useState("");
  const [jenisBeli, setJenisBeli] = useState("");
  const [tanggalBeli, setTanggalBeli] = useState("");
  const [tanggalBeliDate, setTanggalBeliDate] = useState();
  const [tempo, setTempo] = useState("");
  const [tanggalJatuhTempo, setTanggalJatuhTempo] = useState("");
  const [tanggalLunas, setTanggalLunas] = useState("");
  const [totalBeli, setTotalBeli] = useState("");
  const [supplier, setSupplier] = useState("");

  const [belisChildPagination, setBelisChildPagination] = useState([]);
  const navigate = useNavigate();
  let today = new Date();
  let isEditableManager = user.tipeUser !== "ADMIN";
  let isEditableAdmin =
    user.tipeUser === "ADMIN" &&
    tanggalBeliDate?.getFullYear() === today.getFullYear() &&
    tanggalBeliDate?.getMonth() === today.getMonth() &&
    tanggalBeliDate?.getDate() === today.getDate();
  let isEditable = isEditableManager || isEditableAdmin;

  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");
  const PER_PAGE = 20;

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  useEffect(() => {
    getBeliById();
  }, [page]);

  const getBeliById = async () => {
    setLoading(true);
    const response = await axios.post(`${tempUrl}/belis/${id}`, {
      _id: user.id,
      token: user.token,
    });
    setNoNotaBeli(response.data.noNotaBeli);
    setJenisBeli(response.data.jenisBeli);
    setTanggalBeli(response.data.tanggalBeliFormatted);
    setTanggalBeliDate(new Date(response.data.tanggalBeli));
    setTempo(response.data.tempo);
    setTanggalJatuhTempo(response.data.tanggalJatuhTempoFormatted);
    setTanggalLunas(response.data.tanggalLunasFormatted);
    setTotalBeli(response.data.totalBeli);
    setSupplier(response.data.supplier);

    const response2 = await axios.post(
      `${tempUrl}/beliChildsByBeliPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
      {
        beliId: response.data.id,
        kodeCabang: user.cabang.id,
        _id: user.id,
        token: user.token,
      }
    );
    setBelisChildPagination(response2.data.belis);
    setPage(response2.data.page);
    setPages(response2.data.totalPage);
    setRows(response2.data.totalRows);
    setLoading(false);
  };

  const deleteBeli = async (id) => {
    setLoading(true);
    try {
      await axios.post(`${tempUrl}/deleteBeli/${id}`, {
        _id: user.id,
        token: user.token,
      });
      navigate("/daftarBeli");
    } catch (error) {
      alert(error.response.data.message);
    }
    setLoading(false);
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Pembelian</h3>
      <h5 style={{ fontWeight: 400 }}>Beli</h5>
      <hr />
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => navigate("/daftarBeli")}
        sx={{ marginLeft: 2, marginTop: 4 }}
      >
        {"< Kembali"}
      </Button>
      <Box sx={buttonModifierContainer}>
        {isEditable && (
          <>
            <ButtonModifier
              id={id}
              kode={"test"}
              addLink={`/daftarBeli/beli/${id}/tambahBeliChild`}
              editLink={`/daftarBeli/beli/${id}/edit`}
              deleteUser={deleteBeli}
              nameUser={noNotaBeli}
            />
          </>
        )}
      </Box>
      <Form>
        <Card>
          <Card.Header>Beli</Card.Header>
          <Card.Body>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No. Bukti :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={noNotaBeli}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Jenis Beli :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={jenisBeli}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Beli :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={tanggalBeli}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Jml. Hari Hutang :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control required value={tempo} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Jt. Tempo :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={tanggalJatuhTempo}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Lunas :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={tanggalLunas}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Supplier :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={`${supplier.kodeSupplier} - ${supplier.namaSupplier}`}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Total :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={totalBeli.toLocaleString("en-US")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Form>
      <Box sx={tableContainer}>
        <ShowTableBeliChild
          page={page}
          limit={limit}
          id={id}
          currentPosts={belisChildPagination}
        />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Container>
  );
};

export default TampilBeli;

const buttonModifierContainer = {
  mt: 4,
  mb: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const subTitleText = {
  fontWeight: "900",
};
