import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../../contexts/ContextProvider";
import { Loader } from "../../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Alert,
  Button,
  Snackbar,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SaveIcon from "@mui/icons-material/Save";

const TambahJual = () => {
  const { screenSize } = useStateContext();
  const { user } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [noNotaJual, setNoNotaJual] = useState("");
  const [jenisJual, setJenisJual] = useState("");
  const [inputTanggalJual, setInputTanggalJual] = useState(new Date());
  const [kodePelanggan, setKodePelanggan] = useState("");

  const [pelanggans, setPelanggans] = useState([]);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const jenisJualOption = ["TUNAI", "HUTANG"];
  let pelangganOptions = pelanggans.map((pelanggan) => ({
    label: `${pelanggan.kodePelanggan} - ${pelanggan.namaPelanggan}`,
  }));

  useEffect(() => {
    getNextKodeJual(new Date());
    getPelanggansData();
  }, []);

  const getPelanggansData = async (kodeUnit) => {
    const response = await axios.post(`${tempUrl}/pelanggans`, {
      _id: user.id,
      token: user.token,
    });
    setPelanggans(response.data);
  };

  const getNextKodeJual = async (inputTanggalJual) => {
    const nextKodeJual = await axios.post(`${tempUrl}/jualsNextKode`, {
      tanggalJual: inputTanggalJual,
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setNoNotaJual(nextKodeJual.data);
  };

  const saveJual = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (
      form.checkValidity() &&
      jenisJual.length !== 0 &&
      kodePelanggan.length !== 0
    ) {
      setLoading(true);
      try {
        setLoading(true);
        let savedJual = await axios.post(`${tempUrl}/saveJual`, {
          tanggalJual: inputTanggalJual,
          jenisJual,
          tanggalLunas: "",
          lunas: jenisJual === "HUTANG" ? 0 : 1,
          kodePelanggan: kodePelanggan.split(" -", 1)[0],
          userIdInput: user.id,
          kodeCabang: user.cabang.id,
          _id: user.id,
          token: user.token,
        });
        console.log(savedJual);
        setLoading(false);
        navigate(`/daftarJual/jual/${savedJual.data.id}`);
      } catch (error) {
        alert(error);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Transaksi</h3>
      <h5 style={{ fontWeight: 400 }}>Tambah Jual</h5>
      <hr />
      <Card>
        <Card.Header>Jual</Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={saveJual}>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No. Nota :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={noNotaJual}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Jenis Jual <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={jenisJualOption}
                      renderInput={(params) => (
                        <TextField
                          error={error && jenisJual.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setJenisJual(value);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Jual <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <DatePicker
                      required
                      dateFormat="dd/MM/yyyy"
                      selected={inputTanggalJual}
                      customInput={<Form.Control required />}
                      onChange={(date) => {
                        setInputTanggalJual(date);
                        getNextKodeJual(date);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Pelanggan <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={pelangganOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && kodePelanggan.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setKodePelanggan(value);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Box sx={spacingTop}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate("/daftarJual")}
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button
                variant="outlined"
                color="success"
                onClick={() => navigate("/daftarJual/jual/tambahPelanggan")}
                sx={{ marginRight: 2 }}
              >
                {"Tambah Pelanggan"}
              </Button>
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                type="submit"
              >
                Simpan
              </Button>
            </Box>
          </Form>
        </Card.Body>
      </Card>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default TambahJual;

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const subTitleText = {
  fontWeight: "900",
};

const colorRed = {
  color: "red",
};
