import { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../../contexts/ContextProvider";
import { Loader } from "../../../../components";
import { ShowTableBeliChild } from "../../../../components/ShowTable";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Alert,
  Button,
  Snackbar,
  Typography,
  Autocomplete,
  TextField,
  Pagination,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";

const TambahBeliChild = () => {
  const { screenSize } = useStateContext();
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [validated, setValidated] = useState(false);
  // Data Beli
  const [noNotaBeli, setNoNotaBeli] = useState("");
  const [tanggalBeli, setTanggalBeli] = useState("");
  const [tanggalBeliDate, setTanggalBeliDate] = useState("");
  const [totalBeli, setTotalBeli] = useState("");
  // Data Barang
  const [kodeBarang, setKodeBarang] = useState("");
  const [namaBarang, setNamaBarang] = useState("");
  const [jumlah1, setJumlah1] = useState("0");
  const [hargaPokok1, setHargaPokok1] = useState("");
  const [hargaJual1, setHargaJual1] = useState("");
  const [hargaGrosir1, setHargaGrosir1] = useState("");
  const [minimum, setMinimum] = useState("");
  const [subtotalBeliChild, setSubtotalBeliChild] = useState("");

  const [barangs, setBarangs] = useState([]);
  const [belisChildPagination, setBelisChildPagination] = useState([]);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  let barangOptions = barangs.map((barang) => ({
    label: `${barang.kodeBarang} - ${barang.namaBarang}`,
  }));

  useEffect(() => {
    getBarangsData();
    getBeli();
  }, []);

  const getBarangsData = async (kodeUnit) => {
    const response = await axios.post(`${tempUrl}/barangs`, {
      _id: user.id,
      token: user.token,
    });
    setBarangs(response.data);
  };

  const getBeli = async () => {
    setLoading(true);
    const pickedBeli = await axios.post(`${tempUrl}/belis/${id}`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setNoNotaBeli(pickedBeli.data.noNotaBeli);
    let newTanggalBeli = new Date(pickedBeli.data.tanggalBeli);
    let tempTanggalBeli = `${newTanggalBeli.getDate().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}-${(newTanggalBeli.getMonth() + 1).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}-${newTanggalBeli.getFullYear()}`;
    setTanggalBeli(tempTanggalBeli);
    setTanggalBeliDate(pickedBeli.data.tanggalBeli);
    setTotalBeli(pickedBeli.data.totalBeli);

    const response2 = await axios.post(
      `${tempUrl}/beliChildsByBeliPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
      {
        beliId: pickedBeli.data.id,
        kodeCabang: user.cabang.id,
        _id: user.id,
        token: user.token,
      }
    );
    setBelisChildPagination(response2.data.belis);
    setLoading(false);
  };

  const getBarang = async (kodeBarang) => {
    if (kodeBarang.length === 0) {
      return;
    }
    let tempKodeBarang = kodeBarang.split(" -", 1)[0];
    const pickedBarang = await axios.post(`${tempUrl}/barangsByKodeBarang`, {
      kodeBarang: tempKodeBarang,
      _id: user.id,
      token: user.token,
    });
    if (pickedBarang.data) {
      setKodeBarang(pickedBarang.data.kodeBarang);
      setNamaBarang(pickedBarang.data.namaBarang);
      setHargaPokok1(pickedBarang.data.hargaPokok1.toLocaleString("en-US"));
      setHargaPokok1(pickedBarang.data.hargaPokok1.toLocaleString("en-US"));
      setHargaPokok1(pickedBarang.data.hargaPokok1.toLocaleString("en-US"));
      setHargaJual1(pickedBarang.data.hargaJual1.toLocaleString("en-US"));
      setHargaGrosir1(pickedBarang.data.hargaGrosir1.toLocaleString("en-US"));
      setMinimum(pickedBarang.data.minimum.toLocaleString("en-US"));
    }

    setKodeBarang(kodeBarang);
  };

  const saveBeliChild = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() || kodeBarang.length !== 0) {
      setLoading(true);
      try {
        setLoading(true);
        await axios.post(`${tempUrl}/saveBeliChild`, {
          // Data Beli
          noNotaBeli,
          tanggalBeli: tanggalBeliDate,
          beliId: id,

          // Data Barang
          kodeBarang: kodeBarang.split(" -", 1)[0],
          namaBarang: namaBarang.replace(/,/g, ""),
          jumlah1: jumlah1.replace(/,/g, ""),
          hargaPokok1: hargaPokok1.replace(/,/g, ""),
          hargaJual1: hargaJual1.replace(/,/g, ""),
          hargaGrosir1: hargaGrosir1.replace(/,/g, ""),
          minimum: minimum.replace(/,/g, ""),
          subtotalBeliChild: subtotalBeliChild.replace(/,/g, ""),

          userIdInput: user.id,
          kodeCabang: user.cabang.id,
          _id: user.id,
          token: user.token,
        });
        setKodeBarang("");
        setNamaBarang("");
        setJumlah1("0");
        setHargaPokok1("");
        setHargaPokok1("");
        setHargaPokok1("");
        setHargaJual1("");
        setHargaGrosir1("");
        setMinimum("");
        setSubtotalBeliChild("");

        const response2 = await axios.post(
          `${tempUrl}/beliChildsByBeliPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
          {
            beliId: id,
            kodeCabang: user.cabang.id,
            _id: user.id,
            token: user.token,
          }
        );
        setBelisChildPagination(response2.data.belis);
        getBeli();

        setLoading(false);
      } catch (error) {
        alert(error);
      }
      setLoading(false);
      setSuccess(true);
      setOpenSuccess(!openSuccess);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  const textRightSmall = {
    textAlign: screenSize >= 650 && "right",
    fontSize: "14px",
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Transaksi</h3>
      <h5 style={{ fontWeight: 400 }}>Tambah Detail Beli</h5>
      <hr />
      <Card>
        <Card.Header>Detail Beli</Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={saveBeliChild}>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        No. Nota :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          required
                          value={noNotaBeli}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Tanggal :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          required
                          value={tanggalBeli}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Barang <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="9">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={barangOptions}
                          renderInput={(params) => (
                            <TextField
                              error={error && kodeBarang.length === 0 && true}
                              size="small"
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => {
                            getBarang(value);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRightSmall}>
                        Nama <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          required
                          value={namaBarang}
                          onChange={(e) =>
                            setNamaBarang(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRightSmall}>
                        Jumlah 1 :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          value={jumlah1}
                          onChange={(e) => {
                            let tempNum;
                            let isNumNan = isNaN(
                              parseInt(e.target.value.replace(/,/g, ""), 10)
                            );
                            if (isNumNan) {
                              tempNum = "";
                            } else {
                              tempNum = parseInt(
                                e.target.value.replace(/,/g, ""),
                                10
                              ).toLocaleString("en-US");
                            }
                            setJumlah1(tempNum);
                            setSubtotalBeliChild(
                              (
                                tempNum.replace(/,/g, "") *
                                hargaPokok1.replace(/,/g, "")
                              ).toLocaleString("en-US")
                            );
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRightSmall}>
                        Harga Pokok 1 :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          value={hargaPokok1}
                          onChange={(e) => {
                            let tempNum;
                            let isNumNan = isNaN(
                              parseInt(e.target.value.replace(/,/g, ""), 10)
                            );
                            if (isNumNan) {
                              tempNum = "";
                            } else {
                              tempNum = parseInt(
                                e.target.value.replace(/,/g, ""),
                                10
                              ).toLocaleString("en-US");
                            }
                            setHargaPokok1(tempNum);
                            setSubtotalBeliChild(
                              (
                                tempNum.replace(/,/g, "") *
                                jumlah1.replace(/,/g, "")
                              ).toLocaleString("en-US")
                            );
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRightSmall}>
                        Harga Grosir 1 :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          value={hargaGrosir1}
                          onChange={(e) => {
                            let tempNum;
                            let isNumNan = isNaN(
                              parseInt(e.target.value.replace(/,/g, ""), 10)
                            );
                            if (isNumNan) {
                              tempNum = "";
                            } else {
                              tempNum = parseInt(
                                e.target.value.replace(/,/g, ""),
                                10
                              ).toLocaleString("en-US");
                            }
                            setHargaGrosir1(tempNum);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRightSmall}>
                        Harga Jual 1 :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          value={hargaJual1}
                          onChange={(e) => {
                            let tempNum;
                            let isNumNan = isNaN(
                              parseInt(e.target.value.replace(/,/g, ""), 10)
                            );
                            if (isNumNan) {
                              tempNum = "";
                            } else {
                              tempNum = parseInt(
                                e.target.value.replace(/,/g, ""),
                                10
                              ).toLocaleString("en-US");
                            }
                            setHargaJual1(tempNum);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRightSmall}>
                        Minimum :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          value={minimum}
                          onChange={(e) => {
                            let tempNum;
                            let isNumNan = isNaN(
                              parseInt(e.target.value.replace(/,/g, ""), 10)
                            );
                            if (isNumNan) {
                              tempNum = "";
                            } else {
                              tempNum = parseInt(
                                e.target.value.replace(/,/g, ""),
                                10
                              ).toLocaleString("en-US");
                            }
                            setMinimum(tempNum);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Subtotal <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          required
                          value={subtotalBeliChild}
                          onChange={(e) => {
                            let tempNum;
                            let isNumNan = isNaN(
                              parseInt(e.target.value.replace(/,/g, ""), 10)
                            );
                            if (isNumNan) {
                              tempNum = "";
                            } else {
                              tempNum = parseInt(
                                e.target.value.replace(/,/g, ""),
                                10
                              ).toLocaleString("en-US");
                            }
                            setSubtotalBeliChild(tempNum);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
            <Box sx={spacingTop}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate(`/daftarBeli/beli/${id}`)}
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button
                variant="outlined"
                color="success"
                onClick={() => navigate(`/daftarBeli/beli/${id}/tambahBarang`)}
                sx={{ marginRight: 2 }}
              >
                {"Tambah Barang"}
              </Button>
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                type="submit"
              >
                Simpan
              </Button>
            </Box>
          </Form>
        </Card.Body>
      </Card>
      <Box sx={tableContainer}>
        <ShowTableBeliChild id={id} currentPosts={belisChildPagination} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
      <Box sx={containerTotal}>
        <h5>Total : {totalBeli.toLocaleString("en-US")}</h5>
      </Box>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
      {success && (
        <Snackbar
          open={openSuccess}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleCloseSuccess} severity="success" sx={alertBox}>
            Data berhasil ditambahkan!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default TambahBeliChild;

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const subTitleText = {
  fontWeight: "900",
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const colorRed = {
  color: "red",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const containerTotal = {
  display: "flex",
  justifyContent: "flex-end",
};
