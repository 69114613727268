import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import "../../../../constants/report.css";
import { formatDate } from "../../../../constants/helper";
import { AuthContext } from "../../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../../contexts/ContextProvider";
import { ShowTableStokToko } from "../../../../components/ShowTable";
import { FetchErrorHandling } from "../../../../components/FetchErrorHandling";
import { SearchBar, Loader } from "../../../../components";
import { Container, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Pagination,
  Button,
  ButtonGroup,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useDownloadExcel } from "react-export-table-to-excel";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";

const TampilDaftarStokToko = () => {
  const tableRef = useRef(null);
  const { user, dispatch, setting } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();

  const [isFetchError, setIsFetchError] = useState(false);
  const [kodeBarang, setKodeBarang] = useState("");
  const [namaBarang, setNamaBarang] = useState("");
  const [kuantitasStok, setKuantitasStok] = useState("");
  const [cabang, setCabang] = useState("");
  const [value, setValue] = useState("Semua");
  const [urut, setUrut] = useState("Kategori");

  const [previewPdf, setPreviewPdf] = useState(false);
  const [previewExcel, setPreviewExcel] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [stokTokos, setStokTokos] = useState([]);
  const [stokTokoPagination, setStokTokoPagination] = useState([]);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTerm(query);
  };

  useEffect(() => {
    id && getStokTokoById();
  }, [id]);

  useEffect(() => {
    if (user.tipeUser === "OWNER") {
      getStokTokoAllCabangPagination();
    } else {
      getStokTokosPagination();
    }
  }, [page, searchTerm, value, urut]);

  const getStokTokoAllCabangPagination = async () => {
    setLoading(true);
    try {
      let tempUrlSwitch;
      switch (value) {
        case "Semua":
          switch (urut) {
            case "Kategori":
              tempUrlSwitch = "stokTokoAllCabangPagination";
              break;
            case "Abjad":
              tempUrlSwitch = "stokTokoAllCabangPaginationAbjad";
              break;
          }
          break;
        case "Ada Stok":
          switch (urut) {
            case "Kategori":
              tempUrlSwitch = "stokTokoAllCabangAdaStokPagination";
              break;
            case "Abjad":
              tempUrlSwitch = "stokTokoAllCabangAdaStokPaginationAbjad";
              break;
          }
          break;
        case "Stok Habis":
          switch (urut) {
            case "Kategori":
              tempUrlSwitch = "stokTokoAllCabangStokHabisPagination";
              break;
            case "Abjad":
              tempUrlSwitch = "stokTokoAllCabangStokHabisPaginationAbjad";
              break;
          }
          break;
      }

      const response = await axios.post(
        `${tempUrl}/${tempUrlSwitch}?search_query=${searchTerm}&page=${page}&limit=${limit}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      setQuery(searchTerm);
      setStokTokoPagination(response.data.stokTokos);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getStokTokosPagination = async () => {
    try {
      let tempUrlSwitch;
      switch (value) {
        case "Semua":
          switch (urut) {
            case "Kategori":
              tempUrlSwitch = "stokTokosPagination";
              break;
            case "Abjad":
              tempUrlSwitch = "stokTokosPaginationAbjad";
              break;
          }
          break;
        case "Ada Stok":
          switch (urut) {
            case "Kategori":
              tempUrlSwitch = "stokTokosAdaStokPagination";
              break;
            case "Abjad":
              tempUrlSwitch = "stokTokosAdaStokPaginationAbjad";
              break;
          }
          break;
        case "Stok Habis":
          switch (urut) {
            case "Kategori":
              tempUrlSwitch = "stokTokosStokHabisPagination";
              break;
            case "Abjad":
              tempUrlSwitch = "stokTokosStokHabisPaginationAbjad";
              break;
          }
          break;
      }

      const response = await axios.post(
        `${tempUrl}/${tempUrlSwitch}?search_query=${searchTerm}&page=${page}&limit=${limit}`,
        {
          _id: user.id,
          token: user.token,
          kodeCabang: user.cabang.id,
        }
      );
      setStokTokoPagination(response.data.stokTokos);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
  };

  const getStokTokoAllCabang = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${tempUrl}/stokTokoAllCabang`, {
        _id: user.id,
        token: user.token,
      });
      setStokTokos(response.data);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getStokTokos = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${tempUrl}/stokTokos`, {
        _id: user.id,
        token: user.token,
        kodeCabang: user.cabang.id,
      });
      setStokTokos(response.data);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getStokTokoById = async () => {
    if (id) {
      setLoading(true);
      const response = await axios.post(`${tempUrl}/stokTokos/${id}`, {
        _id: user.id,
        token: user.token,
      });
      setKodeBarang(response.data.barang.kodeBarang);
      setNamaBarang(response.data.barang.namaBarang);
      setKuantitasStok(response.data.kuantitasStok);
      setCabang(
        `${response.data.cabang.id} - ${response.data.cabang.namaCabang}`
      );
      setLoading(false);
    }
  };

  const downloadPdf = () => {
    var date = new Date();
    var current_date = formatDate(date);
    var current_time =
      date.getHours().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      ":" +
      date.getMinutes().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      ":" +
      date.getSeconds().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
    const doc = new jsPDF();
    doc.setFontSize(10);
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`${setting.alamatPerusahaan}`, 15, 15);
    doc.setFontSize(14);
    doc.text(`Daftar Stok Toko`, 85, 30);
    doc.setFontSize(8);
    doc.text(`Cabang ${user.cabang.id} - ${user.cabang.namaCabang}`, 80, 35);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      290
    );
    doc.autoTable({
      html: "#table",
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 45,
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
      styles: {
        fontSize: 8,
      },
    });
    doc.save("daftarStokToko.pdf");
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "StokToko",
    sheet: "DaftarStokToko",
  });

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const textRightSmall = {
    textAlign: screenSize >= 650 && "right",
    fontSize: "14px",
  };

  const textTableRight = {
    letterSpacing: "0.01px",
    textAlign: "right",
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Container>
      <h3>Stok</h3>
      <h5 style={{ fontWeight: 400 }}>Daftar Stok Toko</h5>
      <Box sx={downloadButtons}>
        <ButtonGroup variant="outlined" color="secondary">
          <Button
            color="primary"
            startIcon={<SearchIcon />}
            onClick={() => {
              if (user.tipeUser === "OWNER") {
                getStokTokoAllCabang();
              } else {
                getStokTokos();
              }
              setPreviewPdf(!previewPdf);
              setPreviewExcel(false);
            }}
          >
            PDF
          </Button>
          <Button
            color="secondary"
            startIcon={<SearchIcon />}
            onClick={() => {
              if (user.tipeUser === "OWNER") {
                getStokTokoAllCabang();
              } else {
                getStokTokos();
              }
              setPreviewExcel(!previewExcel);
              setPreviewPdf(false);
            }}
          >
            Excel
          </Button>
        </ButtonGroup>
      </Box>
      {previewPdf && (
        <div>
          <Button
            variant="outlined"
            startIcon={<PrintIcon />}
            onClick={() => downloadPdf()}
          >
            CETAK
          </Button>
          <table class="styled-table" id="table" style={{ fontSize: "10px" }}>
            <thead>
              <tr>
                <th>Kode</th>
                <th>Nama</th>
                <th>Kuantitas</th>
                <th>Cabang</th>
              </tr>
            </thead>
            <tbody>
              {stokTokos.map((user, index) => (
                <tr key={user.id}>
                  <td>{user.barang.kodeBarang}</td>
                  <td>{user.barang.namaBarang}</td>
                  <td style={textTableRight}>
                    {user.kuantitasStok.toLocaleString("en-US")}
                  </td>
                  <td>{`${user.cabang.id} - ${user.cabang.namaCabang}`}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div>
        {previewExcel && (
          <Button
            variant="outlined"
            startIcon={<DownloadIcon />}
            onClick={onDownload}
          >
            EXCEL
          </Button>
        )}
        <table
          class="styled-table"
          id="table"
          style={{ fontSize: "10px" }}
          ref={tableRef}
        >
          {previewExcel && (
            <tbody>
              <tr>
                <th>Kode</th>
                <th>Nama</th>
                <th>Kuantitas</th>
                <th>Cabang</th>
              </tr>
              {stokTokos.map((user, index) => (
                <tr key={user.id}>
                  <td>{user.barang.kodeBarang}</td>
                  <td>{user.barang.namaBarang}</td>
                  <td style={textTableRight}>
                    {user.kuantitasStok.toLocaleString("en-US")}
                  </td>
                  <td>{`${user.cabang.id} - ${user.cabang.namaCabang}`}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
      <FormControl sx={{ marginTop: 1 }}>
        <FormLabel id="demo-controlled-radio-buttons-group">Urut</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          defaultValue="Kategori"
          value={urut}
          onChange={(event) => setUrut(event.target.value)}
        >
          <FormControlLabel
            value="Kategori"
            control={<Radio />}
            label="Kategori"
          />
          <FormControlLabel value="Abjad" control={<Radio />} label="Abjad" />
        </RadioGroup>
      </FormControl>
      <br />
      <FormControl sx={{ marginTop: 1 }}>
        <FormLabel id="demo-controlled-radio-buttons-group">
          Kuantitas
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          defaultValue="Semua"
          value={value}
          onChange={(event) => setValue(event.target.value)}
        >
          <FormControlLabel value="Semua" control={<Radio />} label="Semua" />
          <FormControlLabel
            value="Ada Stok"
            control={<Radio />}
            label="Ada Stok"
          />
          <FormControlLabel
            value="Stok Habis"
            control={<Radio />}
            label="Stok Habis"
          />
        </RadioGroup>
      </FormControl>
      {id && (
        <Container>
          <hr />
          <Form>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRightSmall}>
                        Kode :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control value={kodeBarang} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRightSmall}>
                        Nama :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control value={namaBarang} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRightSmall}>
                        Kuantitas :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          value={kuantitasStok.toLocaleString("en-US")}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRightSmall}>
                        Cabang :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control value={cabang} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Form>
        </Container>
      )}
      <hr />
      <Form onSubmit={searchData}>
        <Box sx={searchBarContainer}>
          <SearchBar value={query} setSearchTerm={setQuery} />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disableElevation
          >
            Cari
          </Button>
        </Box>
      </Form>
      <Box sx={tableContainer}>
        <ShowTableStokToko currentPosts={stokTokoPagination} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Container>
  );
};

export default TampilDaftarStokToko;

const downloadButtons = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};
