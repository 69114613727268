import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { Box, Button } from "@mui/material";
import { Container, Form, Row, Col } from "react-bootstrap";
import "../../../../src/constants/report.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from "jspdf";
import SearchIcon from "@mui/icons-material/Search";
import PrintIcon from "@mui/icons-material/Print";

const LaporanStokBarang = () => {
  const { screenSize } = useStateContext();
  const { user, setting } = useContext(AuthContext);
  const reportTemplateRef = useRef(null);
  let nowDate = new Date();
  let [dariTanggal, setDariTanggal] = useState(new Date());
  let [sampaiTanggal, setSampaiTanggal] = useState(new Date());
  const [kodeCabang, setKodeCabang] = useState(user.cabang.id);

  const [cabangs, setCabangs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lapStokBarangsData, setLapStokBarangData] = useState([]);
  const [previewPdf, setPreviewPdf] = useState(false);

  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
    });
    doc.html(reportTemplateRef.current, {
      async callback(doc) {
        await window.open(URL.createObjectURL(doc.output("blob")));
      },
      html2canvas: { scale: 0.44 },
    });
  };

  const tampilPdfCabang = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      let lapStokBarangs = await axios.post(`${tempUrl}/laporanStokBarangCabang`, {
        _id: user.id,
        token: user.token,
        kodeCabang: user.cabang.id,
      });
      setLapStokBarangData(lapStokBarangs.data);
      setPreviewPdf(!previewPdf);
    }
  };

  const tampilPdf = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      let lapStokBarangs = await axios.post(`${tempUrl}/laporanStokBarang`, {
        _id: user.id,
        token: user.token,
      });
      setLapStokBarangData(lapStokBarangs.data);
      setPreviewPdf(!previewPdf);
    }
  };

  useEffect(() => {
    getCabangsData();
  }, []);

  const getCabangsData = async () => {
    const response = await axios.post(`${tempUrl}/cabangs`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setCabangs(response.data);
  };

  const tableText = {
    letterSpacing: "0.01px",
  };

  const tableTextBorder = {
    letterSpacing: "0.01px",
    borderTop: "1px solid black",
  };

  const textTableRight = {
    letterSpacing: "0.01px",
    textAlign: "right",
  };

  const list = [];

  let keys = Object.keys(lapStokBarangsData);

  for (let i = 0; i < lapStokBarangsData.length; i++) {
    list.push(
      <tr style={tableText}>
        <td>
            {lapStokBarangsData[keys[i]].kodeBarang}
        </td>
        <td>
            {lapStokBarangsData[keys[i]].namaBarang}
        </td>
        <td style={textTableRight}>
            {lapStokBarangsData[keys[i]].hargaPokok1}
        </td>
        <td style={textTableRight}>
            {lapStokBarangsData[keys[i]].hargaJual1}
        </td>
        <td style={textTableRight} width="100px">
            {lapStokBarangsData[keys[i]].jumlah1}
        </td>
        <td style={textTableRight} width="100px">
            {lapStokBarangsData[keys[i]].jumlah2}
        </td>
      </tr>
    );
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const textCenter = {
    textAlign: screenSize >= 650 && "center",
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <h3>Laporan</h3>
      <h5 style={{ fontWeight: 400 }}>Laporan Stok Barang </h5>
      <hr />
      <Box sx={spacingTop}>
        <Form onSubmit={tampilPdfCabang}>          
        <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Cabang :
                </Form.Label>
                <Col sm="8">
                  <Form.Select
                    required
                    value={kodeCabang}
                    onChange={(e) => {
                      setKodeCabang(e.target.value);
                      setPreviewPdf(false);
                    }}
                  >
                    {cabangs.map((cabang, index) => (
                      <option value={cabang.id}>
                        {cabang.id} - {cabang.namaCabang}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Button
            variant="contained"
            startIcon={<SearchIcon />}
            type="submit"
            style={{ marginTop: "20px" }}
          >
            LIHAT CABANG
          </Button>
        </Form>
        <Form onSubmit={tampilPdf}>
          <Button
            variant="contained"
            startIcon={<SearchIcon />}
            type="submit"
            style={{ marginTop: "20px", backgroundColor: "orange"} }
          >
            LIHAT GUDANG
          </Button>
        </Form>
      </Box>

      {previewPdf && (
        <div style={{ marginTop: "10px" }}>
          <Button
            variant="outlined"
            startIcon={<PrintIcon />}
            onClick={handleGeneratePdf}
          >
            CETAK
          </Button>
          <div ref={reportTemplateRef} id="content" style={pdfContainer}>
            <p>
              {setting.namaPerusahaan} - {setting.alamatPerusahaan}
            </p>
            <p>({setting.kotaPerusahaan})</p>
            <p>{setting.provinsiPerusahaan}</p>
            <p>NO. TELP. {setting.teleponPerusahaan}</p>
            <p>
              Dicetak Oleh: {user.username} | Tanggal:
              {` ${nowDate.getDate().toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}/${(nowDate.getMonth() + 1).toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}/${nowDate.getFullYear()} `}{" "}
              | Jam:{" "}
              {` ${nowDate.getHours().toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}:${(nowDate.getMinutes() + 1).toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}:${nowDate.getSeconds().toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })} `}
            </p>
            <h5 style={{ textAlign: "center", fontWeight: "700" }}>
              Laporan Stok Barang 
            </h5>            <p></p>
            <table style={{ width: "1000px" }} class="styled-table">
              <thead>
                <tr>
                  <th style={tableTitle}>KODE</th>
                  <th style={tableTitle}>NAMA BARANG</th>
                  <th style={tableTitle}>HARGA POKOK</th>
                  <th style={tableTitle}>HARGA JUAL</th>
                  <th style={tableTitle} colSpan="2">STOK</th>
                </tr>
              </thead>
              <tbody>
                {list}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </Container>
  );
};

export default LaporanStokBarang;

const spacingTop = {
  mt: 4,
};

const pdfContainer = {
  padding: "10px",
  letterSpacing: "0.01px",
};

const tableTitle = {
  border: "1px solid black",
  padding: "10px",
  textAlign: "center"
};

const tableTitleRight = {
  border: "1px solid black",
  padding: "10px",
  textAlign: "right",
};
