import { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { SearchBar, Loader, ButtonModifier } from "../../../components";
import { ShowTableTransferStok } from "../../../components/ShowTable";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import { Box, Button, Pagination, Typography } from "@mui/material";

const TampilTransferStokHeader = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch, setting } = useContext(AuthContext);
  const [noTransferStok, setNoTransferStok] = useState("");
  const [tanggalTransferStok, setTanggalTransferStok] = useState("");
  const [tanggalTransferStokDate, setTanggalTransferStokDate] = useState();
  const [totalStok, setTotalStok] = useState("");
  const [jenisTransfer, setJenisTransfer] = useState("");
  const [keterangan, setKeterangan] = useState("");

  const [transfersPagination, setTransfersPagination] = useState([]);
  const navigate = useNavigate();
  let today = new Date();
  let isEditableManager = user.tipeUser !== "ADMIN";
  let isEditableAdmin =
    user.tipeUser === "ADMIN" &&
    tanggalTransferStokDate?.getFullYear() === today.getFullYear() &&
    tanggalTransferStokDate?.getMonth() === today.getMonth() &&
    tanggalTransferStokDate?.getDate() === today.getDate();
  let isEditable = isEditableManager || isEditableAdmin;

  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");
  const PER_PAGE = 20;

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTerm(query);
  };

  useEffect(() => {
    getTransferStokHeaderById();
  }, [page, searchTerm]);

  const getTransferStokHeaderById = async () => {
    setLoading(true);
    const response = await axios.post(`${tempUrl}/transferStoksHeader/${id}`, {
      _id: user.id,
      token: user.token,
    });
    setNoTransferStok(response.data.noTransferStok);
    setTanggalTransferStok(response.data.tanggalTransferStokFormatted);
    setTanggalTransferStokDate(new Date(response.data.tanggalTransferStok));
    setTotalStok(response.data.totalStok);
    setJenisTransfer(response.data.jenisTransfer);
    setKeterangan(response.data.keterangan);

    const response2 = await axios.post(
      `${tempUrl}/transferStoksByHeaderIdPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
      {
        transferStokHeaderId: id,
        kodeCabang: user.cabang.id,
        _id: user.id,
        token: user.token,
      }
    );
    setQuery(searchTerm);
    setTransfersPagination(response2.data.transferStoks);
    setPage(response2.data.page);
    setPages(response2.data.totalPage);
    setRows(response2.data.totalRows);
    setLoading(false);
  };

  const deleteTransferStokHeader = async (id) => {
    setLoading(true);
    try {
      await axios.post(`${tempUrl}/deleteTransferStokHeader/${id}`, {
        _id: user.id,
        token: user.token,
        kodeCabang: user.cabang.id,
      });
      setSearchTerm("");
      navigate("/daftarTransferStok");
    } catch (error) {
      alert(error.response.data.message);
    }
    setLoading(false);
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Stok</h3>
      <h5 style={{ fontWeight: 400 }}>Transfer Stok</h5>
      <hr />
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => navigate(`/daftarTransferStok`)}
        sx={{ marginLeft: 2, marginTop: 4 }}
      >
        {"< Kembali"}
      </Button>
      <Box sx={buttonModifierContainer}>
        {isEditable && (
          <>
            <ButtonModifier
              id={id}
              kode={"test"}
              addLink={`/daftarTransferStok/transferStok/${id}/tambahTransferStok`}
              editLink={null}
              deleteUser={deleteTransferStokHeader}
              nameUser={noTransferStok}
            />
          </>
        )}
      </Box>
      <Form>
        <Card>
          <Card.Header>Transfer Stok</Card.Header>
          <Card.Body>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No. :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={noTransferStok}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={tanggalTransferStok}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Jenis :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={jenisTransfer}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Total Stok :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={totalStok}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Keterangan :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={keterangan}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Form>
      <Form onSubmit={searchData}>
        <Box sx={searchBarContainer}>
          <SearchBar value={query} setSearchTerm={setQuery} />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disableElevation
          >
            Cari
          </Button>
        </Box>
      </Form>
      <Box sx={tableContainer}>
        <ShowTableTransferStok id={id} currentPosts={transfersPagination} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Container>
  );
};

export default TampilTransferStokHeader;

const buttonModifierContainer = {
  mt: 4,
  mb: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};
