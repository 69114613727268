import { useEffect, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { ShowTableTransferStok } from "../../../components/ShowTable";
import { SearchBar, Loader } from "../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Alert,
  Button,
  Snackbar,
  Autocomplete,
  TextField,
  Pagination,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { formatDate } from "../../../constants/helper";

const TambahTransferStok = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch } = useContext(AuthContext);
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [validated, setValidated] = useState(false);
  const [noTransferStok, setNoTransferStok] = useState("");
  const [tanggalTransferStok, setTanggalTransferStok] = useState("");
  const [inputTanggalTransferStok, setInputTanggalTransferStok] = useState(
    new Date()
  );
  const [kodeBarang, setKodeBarang] = useState("");
  const [kuantitasStok, setKuantitasStok] = useState("");
  const [jenisTransfer, setJenisTransfer] = useState("");
  const [keterangan, setKeterangan] = useState("");

  const [transferStokPagination, setTransferStokPagination] = useState([]);
  const [barangs, setBarangs] = useState([]);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTerm(query);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  let barangOptions = barangs.map((barang) => ({
    label: `${barang.kodeBarang} - ${barang.namaBarang}`,
  }));

  useEffect(() => {
    getBarangsData();
    getTransferStokHeader();
  }, [page, searchTerm]);

  const getBarangsData = async (kodeUnit) => {
    const response = await axios.post(`${tempUrl}/barangs`, {
      _id: user.id,
      token: user.token,
    });
    setBarangs(response.data);
  };

  const getTransferStokHeader = async () => {
    setLoading(true);
    const pickedTransferStokHeader = await axios.post(
      `${tempUrl}/transferStoksHeader/${id}`,
      {
        _id: user.id,
        token: user.token,
        kodeCabang: user.cabang.id,
      }
    );
    setNoTransferStok(pickedTransferStokHeader.data.noTransferStok);
    setTanggalTransferStok(
      formatDate(pickedTransferStokHeader.data.tanggalTransferStok)
    );
    setInputTanggalTransferStok(
      new Date(pickedTransferStokHeader.data.tanggalTransferStok)
    );
    setJenisTransfer(pickedTransferStokHeader.data.jenisTransfer);

    const response2 = await axios.post(
      `${tempUrl}/transferStoksByHeaderIdPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
      {
        transferStokHeaderId: id,
        kodeCabang: user.cabang.id,
        _id: user.id,
        token: user.token,
      }
    );
    setQuery(searchTerm);
    setTransferStokPagination(response2.data.transferStoks);
    setPage(response2.data.page);
    setPages(response2.data.totalPage);
    setRows(response2.data.totalRows);

    setLoading(false);
  };

  const transferStokTokoGudang = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() && kodeBarang.length !== 0) {
      setLoading(true);
      try {
        setLoading(true);
        await axios.post(`${tempUrl}/transferStokTokoGudang`, {
          tanggalTransferStok: inputTanggalTransferStok,
          kodeBarang: kodeBarang.split(" -", 1)[0],
          kuantitasStok,
          jenisTransfer,
          keterangan,
          transferStokHeaderId: id,
          userIdInput: user.id,
          _id: user.id,
          token: user.token,
          kodeCabang: user.cabang.id,
        });
        setLoading(false);
      } catch (error) {
        if (error.response.status == 401) {
          dispatch({ type: "LOGOUT" });
          navigate("/");
        } else {
          alert(error.response.data.message);
        }
      }
      setLoading(false);
      setSuccess(true);
      setOpenSuccess(!openSuccess);
    } else {
      setError(true);
      setOpen(!open);
    }
    setKuantitasStok("");
    setKeterangan("");
    getTransferStokHeader();
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Stok</h3>
      <h5 style={{ fontWeight: 400 }}>Tambah Transfer Stok</h5>
      <hr />
      <Card>
        <Card.Header>Transfer Stok</Card.Header>
        <Card.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={transferStokTokoGudang}
          >
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    No. :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      required
                      value={noTransferStok}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Jenis :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      required
                      value={jenisTransfer}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Tgl. :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      required
                      value={tanggalTransferStok}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Barang <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="9">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={barangOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && kodeBarang.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setKodeBarang(value);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Kuantitas <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      required
                      type="number"
                      value={kuantitasStok}
                      onChange={(e) => setKuantitasStok(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Keterangan :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={keterangan}
                      onChange={(e) => {
                        setKeterangan(e.target.value.toUpperCase());
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Box sx={spacingTop}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() =>
                  navigate(`/daftarTransferStok/transferStok/${id}`)
                }
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                type="submit"
              >
                Simpan
              </Button>
            </Box>
          </Form>
        </Card.Body>
      </Card>
      <Form onSubmit={searchData}>
        <Box sx={searchBarContainer}>
          <SearchBar value={query} setSearchTerm={setQuery} />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disableElevation
          >
            Cari
          </Button>
        </Box>
      </Form>
      <Box sx={tableContainer}>
        <ShowTableTransferStok id={id} currentPosts={transferStokPagination} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
      {success && (
        <Snackbar
          open={openSuccess}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleCloseSuccess} severity="success" sx={alertBox}>
            Data berhasil ditambahkan!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default TambahTransferStok;

const colorRed = {
  color: "red",
};

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};
