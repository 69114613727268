import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { Box, Button } from "@mui/material";
import { Container, Form, Row, Col } from "react-bootstrap";
import "../../../../src/constants/report.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from "jspdf";
import SearchIcon from "@mui/icons-material/Search";
import PrintIcon from "@mui/icons-material/Print";

const LaporanPembelianBarang = () => {
  const { screenSize } = useStateContext();
  const { user, setting } = useContext(AuthContext);
  const reportTemplateRef = useRef(null);
  let nowDate = new Date();
  let [dariTanggal, setDariTanggal] = useState(new Date());
  let [sampaiTanggal, setSampaiTanggal] = useState(new Date());
  const [kodeCabang, setKodeCabang] = useState(user.cabang.id);

  const [cabangs, setCabangs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lapPembelianBarangsData, setLapPembelianBarangData] = useState([]);
  const [previewPdf, setPreviewPdf] = useState(false);

  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
    });
    doc.html(reportTemplateRef.current, {
      async callback(doc) {
        await window.open(URL.createObjectURL(doc.output("blob")));
      },
      html2canvas: { scale: 0.44 },
    });
  };

  const tampilPdf = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      let lapPembelianBarangs = await axios.post(
        `${tempUrl}/laporanPembelianBarang`,
        {
          dariTanggal,
          sampaiTanggal,
          _id: user.id,
          token: user.token,
        }
      );
      setLapPembelianBarangData(lapPembelianBarangs.data);
      setPreviewPdf(!previewPdf);
    }
  };

  useEffect(() => {
    getCabangsData();
  }, []);

  const getCabangsData = async () => {
    const response = await axios.post(`${tempUrl}/cabangs`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setCabangs(response.data);
  };

  const tableText = {
    letterSpacing: "0.01px",
  };

  const tableTextBorder = {
    letterSpacing: "0.01px",
    borderTop: "1px solid black",
  };

  const textTableRight = {
    letterSpacing: "0.01px",
    textAlign: "right",
  };

  const textTableCenter = {
    letterSpacing: "0.01px",
    textAlign: "center",
  };

  const list = [];

  let keys = Object.keys(lapPembelianBarangsData);
  let tempNotaBeliLast;
  if (lapPembelianBarangsData.length) {
    tempNotaBeliLast = lapPembelianBarangsData[keys[0]].noNotaBeli;
  }

  let tempTotalNotaBeli = 0;
  let tempTotal = 0;

  for (let i = 0; i < lapPembelianBarangsData.length; i++) {
    list.push(
      <tr style={tableText}>
        <td>{lapPembelianBarangsData[keys[i]].noNotaBeli}</td>
        <td>{lapPembelianBarangsData[keys[i]].tanggalBeli}</td>
        <td>{lapPembelianBarangsData[keys[i]].namaBarang}</td>
        <td style={textTableRight}>
          {lapPembelianBarangsData[keys[i]].jumlah}
        </td>
        <td style={textTableRight}>
          {lapPembelianBarangsData[keys[i]].hargaPokok.toLocaleString("en-US")}
        </td>
        <td style={textTableRight}>
          {lapPembelianBarangsData[keys[i]].hargaJual.toLocaleString("en-US")}
        </td>
        <td style={textTableRight}>
          {lapPembelianBarangsData[keys[i]].subtotalBeliChild.toLocaleString(
            "en-US"
          )}
        </td>
      </tr>
    );

    tempTotalNotaBeli += lapPembelianBarangsData[keys[i]].subtotalBeliChild;
    tempTotal += lapPembelianBarangsData[keys[i]].subtotalBeliChild;

    if (lapPembelianBarangsData[keys[i + 1]]) {
      if (
        tempNotaBeliLast !== lapPembelianBarangsData[keys[i + 1]].noNotaBeli
      ) {
        list.push(
          <tr style={tableText}>
            <td colspan="6">
              TOTAL {lapPembelianBarangsData[keys[i]].noNotaBeli}
            </td>
            <td style={textTableRight}>
              {tempTotalNotaBeli.toLocaleString("en-US")}
            </td>
          </tr>
        );
        tempNotaBeliLast = lapPembelianBarangsData[keys[i + 1]].noNotaBeli;
        tempTotalNotaBeli = 0;
      }
    }

    if (i === lapPembelianBarangsData.length - 1) {
      list.push(
        <tr style={tableText}>
          <td colspan="6">
            TOTAL {lapPembelianBarangsData[keys[i]].noNotaBeli}
          </td>
          <td style={textTableRight}>
            {tempTotalNotaBeli.toLocaleString("en-US")}
          </td>
        </tr>
      );
      tempTotalNotaBeli = 0;
    }
  }

  list.push(
    <tr style={tableText}>
      <td colspan="6">TOTAL</td>
      <td style={textTableRight}>{tempTotal.toLocaleString("en-US")}</td>
    </tr>
  );

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const textCenter = {
    textAlign: screenSize >= 650 && "center",
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <h3>Laporan</h3>
      <h5 style={{ fontWeight: 400 }}>Laporan Pembelian Barang </h5>
      <hr />
      <Box sx={spacingTop}>
        <Form onSubmit={tampilPdf}>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Dari :
                </Form.Label>
                <Col sm="8">
                  <DatePicker
                    required
                    dateFormat="dd/MM/yyyy"
                    selected={dariTanggal}
                    customInput={<Form.Control required />}
                    onChange={(date) => {
                      setDariTanggal(date);
                      setPreviewPdf(false);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Sampai :
                </Form.Label>
                <Col sm="8">
                  <DatePicker
                    required
                    dateFormat="dd/MM/yyyy"
                    selected={sampaiTanggal}
                    customInput={<Form.Control required />}
                    onChange={(date) => {
                      setSampaiTanggal(date);
                      setPreviewPdf(false);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Button
            variant="contained"
            startIcon={<SearchIcon />}
            type="submit"
            style={{ marginTop: "20px" }}
          >
            LIHAT
          </Button>
        </Form>
      </Box>

      {previewPdf && (
        <div style={{ marginTop: "10px" }}>
          <Button
            variant="outlined"
            startIcon={<PrintIcon />}
            onClick={handleGeneratePdf}
          >
            CETAK
          </Button>
          <div ref={reportTemplateRef} id="content" style={pdfContainer}>
            <p>
              {setting.namaPerusahaan} - {setting.alamatPerusahaan}
            </p>
            <p>({setting.kotaPerusahaan})</p>
            <p>{setting.provinsiPerusahaan}</p>
            <p>NO. TELP. {setting.teleponPerusahaan}</p>
            <p>
              Dicetak Oleh: {user.username} | Tanggal:
              {` ${nowDate.getDate().toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}/${(nowDate.getMonth() + 1).toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}/${nowDate.getFullYear()} `}{" "}
              | Jam:{" "}
              {` ${nowDate.getHours().toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}:${(nowDate.getMinutes() + 1).toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}:${nowDate.getSeconds().toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })} `}
            </p>
            <h5 style={{ textAlign: "center", fontWeight: "700" }}>
              Laporan Pembelian Barang
            </h5>
            <p>
              Dari Tanggal :
              {` ${dariTanggal.getDate().toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}/${(dariTanggal.getMonth() + 1).toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}/${dariTanggal.getFullYear()} `}{" "}
            </p>
            <p>
              Sampai Tanggal :
              {` ${sampaiTanggal.getDate().toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}/${(sampaiTanggal.getMonth() + 1).toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}/${sampaiTanggal.getFullYear()} `}{" "}
            </p>
            <p></p>
            <table style={{ width: "1000px" }} class="styled-table">
              <thead>
                <tr>
                  <th style={tableTitle}>NOTA BELI</th>
                  <th style={tableTitle}>TANGGAL BELI</th>
                  <th style={tableTitle}>NAMA</th>
                  <th style={tableTitle}>JUMLAH</th>
                  <th style={tableTitle}>HARGA POKOK</th>
                  <th style={tableTitle}>HARGA JUAL</th>
                  <th style={tableTitle}>TOTAL</th>
                </tr>
              </thead>
              <tbody>{list}</tbody>
            </table>
          </div>
        </div>
      )}
    </Container>
  );
};

export default LaporanPembelianBarang;

const spacingTop = {
  mt: 4,
};

const pdfContainer = {
  padding: "10px",
  letterSpacing: "0.01px",
};

const tableTitle = {
  border: "1px solid black",
  padding: "10px",
  textAlign: "center",
};

const tableTitleRight = {
  border: "1px solid black",
  padding: "10px",
  textAlign: "right",
};
