import { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../../constants/helper";
import { AuthContext } from "../../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../../contexts/ContextProvider";
import { Loader } from "../../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import { Box, Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const UbahBeliChild = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch } = useContext(AuthContext);
  const { id, idBeliChild } = useParams();
  const [validated, setValidated] = useState(false);
  const [tempIdBeliChild, setTempIdBeliChild] = useState("");
  // Data Beli
  const [noNotaBeli, setNoNotaBeli] = useState("");
  const [tanggalBeli, setTanggalBeli] = useState("");
  const [tanggalBeliDate, setTanggalBeliDate] = useState();
  // Data Barang
  const [kodeBarang, setKodeBarang] = useState("");
  const [namaBarang, setNamaBarang] = useState("");
  const [jumlah1, setJumlah1] = useState("0");
  const [jumlah1Lama, setJumlah1Lama] = useState("0");
  const [hargaPokok1, setHargaPokok1] = useState("");
  const [hargaJual1, setHargaJual1] = useState("");
  const [hargaGrosir1, setHargaGrosir1] = useState("");
  const [minimum, setMinimum] = useState("");
  const [subtotalBeliChild, setSubtotalBeliChild] = useState("");
  const [subtotalBeliChildLama, setSubtotalBeliChildLama] = useState("");

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getBeliChildById();
  }, []);

  const getBeliChildById = async () => {
    if (id) {
      const response = await axios.post(
        `${tempUrl}/beliChilds/${idBeliChild}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      // Data Beli
      setTempIdBeliChild(response.data.id);
      setNoNotaBeli(response.data.noNotaBeli);
      setTanggalBeli(formatDate(response.data.tanggalBeli));
      setTanggalBeliDate(response.data.tanggalBeli);

      // Data Barang
      setKodeBarang(
        `${response.data.barang.kodeBarang} - ${response.data.barang.namaBarang}`
      );
      setNamaBarang(response.data.namaBarang);
      setJumlah1(response.data.jumlah1.toLocaleString("en-US"));
      setJumlah1Lama(response.data.jumlah1.toLocaleString("en-US"));
      setHargaPokok1(response.data.hargaPokok1.toLocaleString("en-US"));
      setHargaJual1(response.data.hargaJual1.toLocaleString("en-US"));
      setHargaGrosir1(response.data.hargaGrosir1.toLocaleString("en-US"));
      setMinimum(response.data.minimum.toLocaleString("en-US"));
      setSubtotalBeliChild(
        response.data.subtotalBeliChild.toLocaleString("en-US")
      );
      setSubtotalBeliChildLama(
        response.data.subtotalBeliChild.toLocaleString("en-US")
      );
    }
  };

  const updateBeliChild = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setLoading(true);
      try {
        setLoading(true);
        await axios.post(`${tempUrl}/updateBeliChild/${idBeliChild}`, {
          // Data Beli
          noNotaBeli,
          tanggalBeli: tanggalBeliDate,
          beliId: id,

          // Data Barang
          kodeBarang: kodeBarang.split(" -", 1)[0],
          namaBarang: namaBarang.replace(/,/g, ""),
          jumlah1: jumlah1.replace(/,/g, ""),
          jumlah1Lama: jumlah1Lama.replace(/,/g, ""),
          hargaPokok1: hargaPokok1.replace(/,/g, ""),
          hargaJual1: hargaJual1.replace(/,/g, ""),
          hargaGrosir1: hargaGrosir1.replace(/,/g, ""),
          minimum: minimum.replace(/,/g, ""),
          subtotalBeliChild: subtotalBeliChild.replace(/,/g, ""),
          subtotalBeliChildLama: subtotalBeliChildLama.replace(/,/g, ""),

          userIdInput: user.id,
          kodeCabang: user.cabang.id,
          _id: user.id,
          token: user.token,
        });

        setLoading(false);
        navigate(`/daftarBeli/beli/${id}/${idBeliChild}`);
      } catch (error) {
        alert(error);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  const textRightSmall = {
    textAlign: screenSize >= 650 && "right",
    fontSize: "14px",
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Pembelian</h3>
      <h5 style={{ fontWeight: 400 }}>Ubah Detail Beli</h5>
      <hr />
      <Card>
        <Card.Header>Ubah Detail Beli</Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={updateBeliChild}>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        No. Nota :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          required
                          value={noNotaBeli}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Tanggal :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          required
                          value={tanggalBeli}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Barang :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          required
                          value={kodeBarang}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRightSmall}>
                        Nama :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          required
                          value={namaBarang}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRightSmall}>
                        Jumlah 1 :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          value={jumlah1}
                          onChange={(e) => {
                            let tempNum;
                            let isNumNan = isNaN(
                              parseInt(e.target.value.replace(/,/g, ""), 10)
                            );
                            if (isNumNan) {
                              tempNum = "";
                            } else {
                              tempNum = parseInt(
                                e.target.value.replace(/,/g, ""),
                                10
                              ).toLocaleString("en-US");
                            }
                            setJumlah1(tempNum);
                            setSubtotalBeliChild(
                              (
                                tempNum.replace(/,/g, "") *
                                hargaPokok1.replace(/,/g, "")
                              ).toLocaleString("en-US")
                            );
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRightSmall}>
                        Harga Pokok 1 :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          value={hargaPokok1}
                          onChange={(e) => {
                            let tempNum;
                            let isNumNan = isNaN(
                              parseInt(e.target.value.replace(/,/g, ""), 10)
                            );
                            if (isNumNan) {
                              tempNum = "";
                            } else {
                              tempNum = parseInt(
                                e.target.value.replace(/,/g, ""),
                                10
                              ).toLocaleString("en-US");
                            }
                            setHargaPokok1(tempNum);
                            setSubtotalBeliChild(
                              (
                                tempNum.replace(/,/g, "") *
                                jumlah1.replace(/,/g, "")
                              ).toLocaleString("en-US")
                            );
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRightSmall}>
                        Harga Grosir 1 :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          value={hargaGrosir1}
                          onChange={(e) => {
                            let tempNum;
                            let isNumNan = isNaN(
                              parseInt(e.target.value.replace(/,/g, ""), 10)
                            );
                            if (isNumNan) {
                              tempNum = "";
                            } else {
                              tempNum = parseInt(
                                e.target.value.replace(/,/g, ""),
                                10
                              ).toLocaleString("en-US");
                            }
                            setHargaGrosir1(tempNum);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRightSmall}>
                        Harga Jual 1 :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          value={hargaJual1}
                          onChange={(e) => {
                            let tempNum;
                            let isNumNan = isNaN(
                              parseInt(e.target.value.replace(/,/g, ""), 10)
                            );
                            if (isNumNan) {
                              tempNum = "";
                            } else {
                              tempNum = parseInt(
                                e.target.value.replace(/,/g, ""),
                                10
                              ).toLocaleString("en-US");
                            }
                            setHargaJual1(tempNum);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRightSmall}>
                        Minimum :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          value={minimum}
                          onChange={(e) => {
                            let tempNum;
                            let isNumNan = isNaN(
                              parseInt(e.target.value.replace(/,/g, ""), 10)
                            );
                            if (isNumNan) {
                              tempNum = "";
                            } else {
                              tempNum = parseInt(
                                e.target.value.replace(/,/g, ""),
                                10
                              ).toLocaleString("en-US");
                            }
                            setMinimum(tempNum);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Subtotal :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          required
                          value={subtotalBeliChild}
                          onChange={(e) => {
                            let tempNum;
                            let isNumNan = isNaN(
                              parseInt(e.target.value.replace(/,/g, ""), 10)
                            );
                            if (isNumNan) {
                              tempNum = "";
                            } else {
                              tempNum = parseInt(
                                e.target.value.replace(/,/g, ""),
                                10
                              ).toLocaleString("en-US");
                            }
                            setSubtotalBeliChild(tempNum);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
            <Box>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() =>
                  navigate(`/daftarBeli/beli/${id}/${idBeliChild}`)
                }
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button
                variant="contained"
                startIcon={<EditIcon />}
                type="submit"
              >
                Edit
              </Button>
            </Box>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default UbahBeliChild;

const deleteButtonContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const colorRed = {
  color: "red",
};
