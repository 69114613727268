import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { ShowTableDaftarTransferStokBarang } from "../../../components/ShowTable";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import { SearchBar, Loader } from "../../../components";
import { Container, Form, Card, Row, Col } from "react-bootstrap";
import { Box, Typography, Divider, Pagination, Button } from "@mui/material";

const TampilDaftarTransferStokBarang = () => {
  const { user, dispatch } = useContext(AuthContext);
  const { screenSize } = useStateContext();
  const { id } = useParams();
  const navigate = useNavigate();
  const [isFetchError, setIsFetchError] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [value, setValue] = useState("No. Nota");
  const [transferStokBarangsPagination, setTransferStokBarangsPagination] =
    useState([]);

  const [tanggalTransferStok, setTanggalTransferStok] = useState("");
  const [kodeBarang, setKodeBarang] = useState("");
  const [namaBarang, setNamaBarang] = useState("");
  const [kuantitasStok, setKuantitasStok] = useState("");
  const [jenisTransfer, setJenisTransfer] = useState("");
  const [keterangan, setKeterangan] = useState("");

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTerm(query);
  };

  useEffect(() => {
    getTransferStokBarangsPagination();
  }, [page, searchTerm, value]);

  useEffect(() => {
    id && getTransferStokBarangById();
  }, [id]);

  const getTransferStokBarangsPagination = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${tempUrl}/transferStoksPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
        {
          _id: user.id,
          token: user.token,
          kodeCabang: user.cabang.id,
        }
      );
      setQuery(searchTerm);
      setTransferStokBarangsPagination(response.data.transferStoks);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getTransferStokBarangById = async () => {
    if (id) {
      setLoading(true);
      const response = await axios.post(`${tempUrl}/transferStoks/${id}`, {
        _id: user.id,
        token: user.token,
        kodeCabang: user.cabang.id,
      });
      setTanggalTransferStok(response.data.tanggalTransferStokFormatted);
      setKodeBarang(response.data.barang.kodeBarang);
      setNamaBarang(response.data.namaBarang);
      setKuantitasStok(response.data.kuantitasStok);
      setJenisTransfer(response.data.jenisTransfer);
      setKeterangan(response.data.keterangan);
      setLoading(false);
    }
  };

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Box>
      <Typography color="#757575">Stok</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Daftar Transfer Stok Barang
      </Typography>
      <Divider sx={dividerStyle} />
      {id && (
        <>
          <Box>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate(`/daftarTransferStokBarang`)}
              sx={{ marginRight: 2 }}
            >
              {"< Kembali"}
            </Button>
          </Box>
          <Card style={spacingTop}>
            <Card.Header>Transfer Stok</Card.Header>
            <Card.Body>
              <Form>
                <Row>
                  <Col sm={6}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Tgl. Transfer :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          value={tanggalTransferStok}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Kode Barang :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control value={kodeBarang} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Nama Barang :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control value={namaBarang} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Kuantitas :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control value={kuantitasStok} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Jenis Transfer :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control value={jenisTransfer} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Keterangan :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={keterangan}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </>
      )}
      <Form onSubmit={searchData}>
        <Box sx={searchBarContainer}>
          <SearchBar value={query} setSearchTerm={setQuery} />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disableElevation
          >
            Cari
          </Button>
        </Box>
      </Form>
      <Box sx={tableContainer}>
        <ShowTableDaftarTransferStokBarang
          currentPosts={transferStokBarangsPagination}
        />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Box>
  );
};

export default TampilDaftarTransferStokBarang;

const container = {
  p: 4,
};

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  pt: 4,
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const spacingTop = {
  marginTop: "40px",
};
