import { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../../contexts/ContextProvider";
import { Loader } from "../../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Alert,
  Button,
  Snackbar,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SaveIcon from "@mui/icons-material/Save";

const UbahBeli = () => {
  const { screenSize } = useStateContext();
  const { user } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [noNotaBeli, setNoNotaBeli] = useState("");
  const [jenisBeli, setJenisBeli] = useState("");
  const [inputTanggalBeli, setInputTanggalBeli] = useState(new Date());
  const [tempo, setTempo] = useState("");
  const [inputTanggalJatuhTempo, setInputTanggalJatuhTempo] = useState(
    new Date()
  );
  const [kodeSupplier, setKodeSupplier] = useState("");
  const [totalBeli, setTotalBeli] = useState("");

  const [suppliers, setSuppliers] = useState([]);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const jenisBeliOption = ["TUNAI", "HUTANG"];
  let supplierOptions = suppliers.map((supplier) => ({
    label: `${supplier.kodeSupplier} - ${supplier.namaSupplier}`,
  }));

  useEffect(() => {
    getSuppliersData();
    getBeliById();
  }, []);

  const getBeliById = async () => {
    setLoading(true);
    const response = await axios.post(`${tempUrl}/belis/${id}`, {
      _id: user.id,
      token: user.token,
    });
    setNoNotaBeli(response.data.noNotaBeli);
    setJenisBeli(response.data.jenisBeli);
    setInputTanggalBeli(new Date(response.data.tanggalBeli));
    setTempo(response.data.tempo);

    let tempTanggalJatuhTempo = response.data.tanggalJatuhTempo;
    if (tempTanggalJatuhTempo != "0000-00-00") {
      setInputTanggalJatuhTempo(new Date(response.data.tanggalJatuhTempo));
    } else {
      setInputTanggalJatuhTempo(new Date());
    }
    setKodeSupplier(
      `${response.data.supplier.kodeSupplier} - ${response.data.supplier.namaSupplier}`
    );
    setTotalBeli(response.data.totalBeli.toLocaleString("en-US"));
    setLoading(false);
  };

  const getSuppliersData = async (kodeUnit) => {
    const response = await axios.post(`${tempUrl}/suppliers`, {
      _id: user.id,
      token: user.token,
    });
    setSuppliers(response.data);
  };

  const updateBeli = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (
      form.checkValidity() &&
      jenisBeli.length !== 0 &&
      kodeSupplier.length !== 0
    ) {
      setLoading(true);
      try {
        setLoading(true);
        await axios.post(`${tempUrl}/updateBeli/${id}`, {
          tanggalBeli: inputTanggalBeli,
          jenisBeli,
          tempo: jenisBeli === "HUTANG" ? tempo : 0,
          tanggalJatuhTempo:
            jenisBeli === "HUTANG" ? inputTanggalJatuhTempo : "",
          tanggalLunas: "",
          kodeSupplier: kodeSupplier.split(" -", 1)[0],
          totalBeli: totalBeli.replace(/,/g, ""),
          userIdUpdate: user.id,
          kodeCabang: user.cabang.id,
          _id: user.id,
          token: user.token,
        });
        setLoading(false);
        navigate(`/daftarBeli/beli/${id}`);
      } catch (error) {
        alert(error);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  const countDateDuration = (e, value) => {
    let numberOfDaysToAdd =
      e.target.value !== "" ? parseInt(e.target.value) : 0;
    let tempInputTanggalBeli = new Date(inputTanggalBeli);
    let result = tempInputTanggalBeli.setDate(
      tempInputTanggalBeli.getDate() + numberOfDaysToAdd
    );
    let finalDate = new Date(result);

    setInputTanggalJatuhTempo(finalDate);
    setTempo(e.target.value.toUpperCase());
  };

  const textRightSmall = {
    textAlign: screenSize >= 650 && "right",
    fontSize: "14px",
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Transaksi</h3>
      <h5 style={{ fontWeight: 400 }}>Ubah Beli</h5>
      <hr />
      <Card>
        <Card.Header>Beli</Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={updateBeli}>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No. Nota :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={noNotaBeli}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Jenis Beli <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={jenisBeliOption}
                      renderInput={(params) => (
                        <TextField
                          error={error && jenisBeli.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setJenisBeli(value);
                      }}
                      value={jenisBeli}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Beli <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <DatePicker
                      required
                      dateFormat="dd/MM/yyyy"
                      selected={inputTanggalBeli}
                      customInput={<Form.Control required />}
                      onChange={(date) => setInputTanggalBeli(date)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tempo <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    {jenisBeli === "TUNAI" ? (
                      <Form.Control required value={""} disabled readOnly />
                    ) : (
                      <Form.Control
                        required
                        type="number"
                        value={tempo}
                        onChange={(e, value) => countDateDuration(e, value)}
                      />
                    )}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Jt. Tempo :
                  </Form.Label>
                  <Col sm="8">
                    {jenisBeli === "TUNAI" ? (
                      <Form.Control required value={""} disabled readOnly />
                    ) : (
                      <DatePicker
                        required
                        dateFormat="dd/MM/yyyy"
                        selected={inputTanggalJatuhTempo}
                        customInput={<Form.Control required />}
                        disabled
                        readOnly
                      />
                    )}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Supplier <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={supplierOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && kodeSupplier.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setKodeSupplier(value);
                      }}
                      value={kodeSupplier}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRightSmall}>
                    Total :
                  </Form.Label>
                  <Col sm="8">
                    {user.tipeUser === "ADMIN" ? (
                      <Form.Control
                        required
                        value={totalBeli}
                        disabled
                        readOnly
                      />
                    ) : (
                      <Form.Control
                        value={totalBeli}
                        onChange={(e) => {
                          let tempNum;
                          let isNumNan = isNaN(
                            parseInt(e.target.value.replace(/,/g, ""), 10)
                          );
                          if (isNumNan) {
                            tempNum = "";
                          } else {
                            tempNum = parseInt(
                              e.target.value.replace(/,/g, ""),
                              10
                            ).toLocaleString("en-US");
                          }
                          setTotalBeli(tempNum);
                        }}
                      />
                    )}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Box sx={spacingTop}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate(`/daftarBeli/beli/${id}`)}
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                type="submit"
              >
                Simpan
              </Button>
            </Box>
          </Form>
        </Card.Body>
      </Card>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default UbahBeli;

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const subTitleText = {
  fontWeight: "900",
};

const colorRed = {
  color: "red",
};
