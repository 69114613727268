import { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../../contexts/ContextProvider";
import { Loader } from "../../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Alert,
  Button,
  Snackbar,
  Autocomplete,
  TextField,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SaveIcon from "@mui/icons-material/Save";

const TambahKoreksiStokGudang = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [inputTanggalKoreksiStokGudang, setInputTanggalKoreksiStokGudang] =
    useState(new Date());
  const [kodeBarang, setKodeBarang] = useState("");
  const [kuantitasStok, setKuantitasStok] = useState("");
  const [perubahanKuantitasStok, setPerubahanKuantitasStok] = useState("");
  const [keterangan, setKeterangan] = useState("");

  const [barangs, setBarangs] = useState([]);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  let barangOptions = barangs.map((barang) => ({
    label: `${barang.kodeBarang} - ${barang.namaBarang}`,
  }));

  useEffect(() => {
    getBarangsData();
  }, []);

  const getBarangsData = async (kodeUnit) => {
    const response = await axios.post(`${tempUrl}/barangs`, {
      _id: user.id,
      token: user.token,
    });
    setBarangs(response.data);
  };

  const getBarang = async (kodeBarang) => {
    if (kodeBarang.length === 0) {
      return;
    }
    let tempKodeBarang = kodeBarang.split(" -", 1)[0];
    const pickedBarang = await axios.post(
      `${tempUrl}/stokGudangsByKodeBarang`,
      {
        kodeBarang: tempKodeBarang,
        _id: user.id,
        token: user.token,
      }
    );
    if (pickedBarang.data) {
      setKuantitasStok(pickedBarang.data.kuantitasStok);
    }

    setKodeBarang(kodeBarang);
  };

  const updateKuantitasKoreksiStokGudang = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() && kodeBarang.length !== 0) {
      setLoading(true);
      try {
        setLoading(true);
        await axios.post(`${tempUrl}/updateKuantitasKoreksiStokGudang`, {
          tanggalKoreksiStokGudang: inputTanggalKoreksiStokGudang,
          kodeBarang: kodeBarang.split(" -", 1)[0],
          kuantitasStok,
          perubahanKuantitasStok,
          keterangan,
          userIdInput: user.id,
          _id: user.id,
          token: user.token,
          kodeCabang: user.cabang.id,
        });
        setLoading(false);
        navigate("/koreksiStokGudang");
      } catch (error) {
        if (error.response.status == 401) {
          dispatch({ type: "LOGOUT" });
          navigate("/");
        } else {
          alert(error.response.data.message);
        }
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Stok</h3>
      <h5 style={{ fontWeight: 400 }}>Tambah Koreksi Stok Gudang</h5>
      <hr />
      <Card>
        <Card.Header>Koreksi Stok Gudang</Card.Header>
        <Card.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={updateKuantitasKoreksiStokGudang}
          >
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Koreksi <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <DatePicker
                      required
                      dateFormat="dd/MM/yyyy"
                      selected={inputTanggalKoreksiStokGudang}
                      customInput={<Form.Control required />}
                      onChange={(date) => {
                        setInputTanggalKoreksiStokGudang(date);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Barang <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={barangOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && kodeBarang.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        getBarang(value);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Stok Awal <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      type="number"
                      value={kuantitasStok}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Koreksi <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      type="number"
                      value={perubahanKuantitasStok}
                      onChange={(e) =>
                        setPerubahanKuantitasStok(e.target.value)
                      }
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Stok Akhir <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      type="number"
                      value={
                        parseInt(kuantitasStok) +
                        parseInt(perubahanKuantitasStok)
                      }
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Keterangan :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={keterangan}
                      onChange={(e) => {
                        setKeterangan(e.target.value.toUpperCase());
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Box sx={spacingTop}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate("/koreksiStokGudang")}
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                type="submit"
              >
                Simpan
              </Button>
            </Box>
          </Form>
        </Card.Body>
      </Card>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default TambahKoreksiStokGudang;

const colorRed = {
  color: "red",
};

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};
