import { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Colors } from "../../../constants/styles";
import { Loader, SearchBar } from "../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Alert,
  Button,
  Snackbar,
  Autocomplete,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@mui/styles";
import SaveIcon from "@mui/icons-material/Save";

const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: Colors.blue700,
    },
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
});

const TambahPembayaranHutang = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [inputTanggalPembayaranHutang, setInputTanggalPembayaranHutang] =
    useState(new Date());
  const [beliId, setBeliId] = useState("");
  const [noNotaBeli, setNoNotaBeli] = useState("");
  const [noPembayaranHutang, setNoPembayaranHutang] = useState("");
  const [jenisPembayaranHutang, setJenisPembayaranHutang] = useState("");
  const [totalPembayaranHutang, setTotalPembayaranHutang] = useState("");

  const [namaSupplier, setNamaSupplier] = useState("");
  const [totalBeli, setTotalBeli] = useState("");

  const [openBeli, setOpenBeli] = useState(false);
  const [searchTermBeli, setSearchTermBeli] = useState("");
  const [belis, setBelis] = useState([]);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  const handleClickOpenBeli = () => {
    setOpenBeli(true);
  };

  const handleCloseBeli = () => {
    setOpenBeli(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const tempPostsBeli = belis.filter((val) => {
    if (searchTermBeli === "") {
      return val;
    } else if (
      val.noNotaBeli.toUpperCase().includes(searchTermBeli.toUpperCase()) ||
      val.tanggalJatuhTempoFormatted
        .toUpperCase()
        .includes(searchTermBeli.toUpperCase()) ||
      val.totalBeli == searchTermBeli ||
      val.supplier.namaSupplier
        .toUpperCase()
        .includes(searchTermBeli.toUpperCase())
    ) {
      return val;
    }
  });

  const jenisPembayaranHutangOption = ["TUNAI", "TRANSFER"];

  useEffect(() => {
    getNextKodePembayaranHutang();
    getBelisData();
  }, []);

  const getNextKodePembayaranHutang = async () => {
    const nextKodePembayaranHutang = await axios.post(
      `${tempUrl}/pembayaranHutangsNextKode`,
      {
        _id: user.id,
        token: user.token,
        kodeCabang: user.cabang.id,
      }
    );
    setNoPembayaranHutang(nextKodePembayaranHutang.data);
  };

  const getBelisData = async () => {
    const response = await axios.post(`${tempUrl}/belisBelumLunas`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setBelis(response.data);
  };

  const savePembayaranHutang = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (
      form.checkValidity() &&
      noNotaBeli.length !== 0 &&
      jenisPembayaranHutang.length !== 0
    ) {
      setLoading(true);
      try {
        setLoading(true);
        await axios.post(`${tempUrl}/savePembayaranHutang`, {
          beliId,
          tanggalPembayaranHutang: inputTanggalPembayaranHutang,
          jenisPembayaranHutang,
          totalPembayaranHutang: totalPembayaranHutang.replace(/,/g, ""),
          userIdInput: user.id,
          _id: user.id,
          token: user.token,
          kodeCabang: user.cabang.id,
        });
        setLoading(false);
        navigate("/pembayaranHutang");
      } catch (error) {
        if (error.response.status == 401) {
          dispatch({ type: "LOGOUT" });
          navigate("/");
        } else {
          alert(error.response.data.message);
        }
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Pembelian</h3>
      <h5 style={{ fontWeight: 400 }}>Tambah Pembayaran Hutang</h5>
      <hr />
      <Card>
        <Card.Header>Pembayaran Hutang</Card.Header>
        <Card.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={savePembayaranHutang}
          >
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No. Bayar <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={noPembayaranHutang}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Bayar <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <DatePicker
                      required
                      dateFormat="dd/MM/yyyy"
                      selected={inputTanggalPembayaranHutang}
                      customInput={<Form.Control required />}
                      onChange={(date) => {
                        setInputTanggalPembayaranHutang(date);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No. Beli <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={noNotaBeli}
                      readOnly
                      placeholder="Pilih..."
                      onClick={() => handleClickOpenBeli()}
                      isInvalid={noNotaBeli.length === 0 && true}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Nama Supplier <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={namaSupplier}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Jenis Bayar <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={jenisPembayaranHutangOption}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          error={
                            error && jenisPembayaranHutang.length === 0 && true
                          }
                          helperText={
                            error &&
                            jenisPembayaranHutang.length === 0 &&
                            "Jenis Bayar harus diisi!"
                          }
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setJenisPembayaranHutang(value);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Total Hutang :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={totalBeli} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Total Bayar :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={totalPembayaranHutang}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Box sx={spacingTop}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate("/pembayaranHutang")}
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                type="submit"
              >
                Simpan
              </Button>
            </Box>
          </Form>
        </Card.Body>
      </Card>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
      <Dialog
        open={openBeli}
        onClose={handleCloseBeli}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Pilih Data Beli`}</DialogTitle>
        <DialogActions>
          <Box sx={dialogContainer}>
            <SearchBar setSearchTerm={setSearchTermBeli} />
            <TableContainer component={Paper} sx={dialogWrapper}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      No. Beli
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Tgl. Jt. Tempo
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Total
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Supplier
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tempPostsBeli
                    .filter((val) => {
                      if (searchTermBeli === "") {
                        return val;
                      } else if (
                        val.noNotaBeli
                          .toUpperCase()
                          .includes(searchTermBeli.toUpperCase()) ||
                        val.tanggalJatuhTempoFormatted
                          .toUpperCase()
                          .includes(searchTermBeli.toUpperCase()) ||
                        val.totalBeli == searchTermBeli ||
                        val.supplier.namaSupplier
                          .toUpperCase()
                          .includes(searchTermBeli.toUpperCase())
                      ) {
                        return val;
                      }
                    })
                    .map((user, index) => (
                      <TableRow
                        key={user._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          "&:hover": { bgcolor: Colors.grey300 },
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setBeliId(user.id);
                          setNoNotaBeli(user.noNotaBeli);
                          setNamaSupplier(user.supplier.namaSupplier);
                          setTotalPembayaranHutang(
                            user.totalBeli.toLocaleString("en-US")
                          );
                          setTotalBeli(user.totalBeli.toLocaleString("en-US"));
                          handleCloseBeli();
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {user.noNotaBeli}
                        </TableCell>
                        <TableCell>{user.tanggalJatuhTempoFormatted}</TableCell>
                        <TableCell align="right">
                          {user.totalBeli.toLocaleString("en-US")}
                        </TableCell>
                        <TableCell>{user.supplier.namaSupplier}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default TambahPembayaranHutang;

const colorRed = {
  color: "red",
};

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const dialogContainer = {
  display: "flex",
  flexDirection: "column",
  width: "800px",
};

const dialogWrapper = {
  width: "100%",
  marginTop: 2,
};
