import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { ShowTablePembayaranPiutang } from "../../../components/ShowTable";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import { SearchBar, Loader, ButtonModifier } from "../../../components";
import { Container, Form, Row, Col } from "react-bootstrap";
import { Box, Pagination, Button, ButtonGroup } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useDownloadExcel } from "react-export-table-to-excel";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";

const TampilPembayaranPiutang = () => {
  const tableRef = useRef(null);
  const { user, dispatch, setting } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();

  const [isFetchError, setIsFetchError] = useState(false);
  const [tanggalPembayaranPiutang, setTanggalPembayaranPiutang] = useState("");
  const [noPembayaranPiutang, setNoPembayaranPiutang] = useState("");
  const [totalPembayaranPiutang, setTotalPembayaranPiutang] = useState("");
  const [jenisPembayaranPiutang, setJenisPembayaranPiutang] = useState("");

  const [jualId, setJualId] = useState("");
  const [noNotaJual, setNoNotaJual] = useState("");
  const [namaPelanggan, setNamaPelanggan] = useState("");
  const [totalJual, setTotalJual] = useState("");

  const [previewPdf, setPreviewPdf] = useState(false);
  const [previewExcel, setPreviewExcel] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [pembayaranPiutangs, setPembayaranPiutangs] = useState([]);
  const [pembayaranPiutangsPagination, setPembayaranPiutangsPagination] =
    useState([]);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");
  const PER_PAGE = 20;

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTerm(query);
  };

  useEffect(() => {
    id && getPembayaranPiutangById();
  }, [id]);

  useEffect(() => {
    getPembayaranPiutangsPagination();
  }, [page, searchTerm]);

  const getPembayaranPiutangsPagination = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${tempUrl}/pembayaranPiutangsPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
        {
          _id: user.id,
          token: user.token,
          kodeCabang: user.cabang.id,
        }
      );
      setQuery(searchTerm);
      setPembayaranPiutangsPagination(response.data.pembayaranPiutangs);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
    } catch (err) {
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getPembayaranPiutangs = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${tempUrl}/pembayaranPiutangs`, {
        _id: user.id,
        token: user.token,
        kodeCabang: user.cabang.id,
      });
      setPembayaranPiutangs(response.data);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getPembayaranPiutangById = async () => {
    if (id) {
      setLoading(true);
      const response = await axios.post(`${tempUrl}/pembayaranPiutangs/${id}`, {
        _id: user.id,
        token: user.token,
        kodeCabang: user.cabang.id,
      });
      setTanggalPembayaranPiutang(
        response.data.tanggalPembayaranPiutangFormatted
      );
      setNoPembayaranPiutang(response.data.noPembayaranPiutang);
      setTotalPembayaranPiutang(
        response.data.totalPembayaranPiutang.toLocaleString("en-US")
      );
      setJenisPembayaranPiutang(response.data.jenisPembayaranPiutang);

      setJualId(response.data.jual.id);
      setNoNotaJual(response.data.jual.noNotaJual);
      setNamaPelanggan(response.data.jual.pelanggan.namaPelanggan);
      setTotalJual(response.data.jual.totalJual.toLocaleString("en-US"));
      setLoading(false);
    }
  };

  const deletePembayaranPiutang = async (id) => {
    setLoading(true);
    try {
      await axios.post(`${tempUrl}/deletePembayaranPiutang/${id}`, {
        jualId,
        _id: user.id,
        token: user.token,
        kodeCabang: user.cabang.id,
      });
      setSearchTerm("");
      setTanggalPembayaranPiutang("");
      setNoPembayaranPiutang("");
      setTotalPembayaranPiutang("");
      setJenisPembayaranPiutang("");

      setJualId("");
      setNoNotaJual("");
      setNamaPelanggan("");
      setTotalJual("");
      getPembayaranPiutangsPagination();
      navigate("/pembayaranPiutang");
    } catch (error) {
      if (error.response.data.message.includes("foreign key")) {
        alert(
          `${totalPembayaranPiutang} tidak bisa dihapus karena sudah ada data!`
        );
      }
    }
    setLoading(false);
  };

  const downloadPdf = () => {
    var date = new Date();
    var current_date =
      date.getDate().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      "-" +
      (date.getMonth() + 1).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      "-" +
      date.getFullYear();
    var current_time =
      date.getHours().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      ":" +
      date.getMinutes().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      ":" +
      date.getSeconds().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`${setting.alamatPerusahaan}`, 15, 15);
    doc.setFontSize(16);
    doc.text(`Daftar Pembayaran Piutang`, 85, 30);
    doc.setFontSize(10);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      290
    );
    doc.autoTable({
      html: "#table",
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 45,
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
    });
    doc.save("daftarPembayaranPiutang.pdf");
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "PembayaranPiutang",
    sheet: "DaftarPembayaranPiutang",
  });

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Container>
      <h3>Penjualan</h3>
      <h5 style={{ fontWeight: 400 }}>Pembayaran Piutang</h5>
      <Box sx={downloadButtons}>
        <ButtonGroup variant="outlined" color="secondary">
          <Button
            color="primary"
            startIcon={<SearchIcon />}
            onClick={() => {
              getPembayaranPiutangs();
              setPreviewPdf(!previewPdf);
              setPreviewExcel(false);
            }}
          >
            PDF
          </Button>
          <Button
            color="secondary"
            startIcon={<SearchIcon />}
            onClick={() => {
              getPembayaranPiutangs();
              setPreviewExcel(!previewExcel);
              setPreviewPdf(false);
            }}
          >
            Excel
          </Button>
        </ButtonGroup>
      </Box>
      {previewPdf && (
        <div>
          <Button
            variant="outlined"
            startIcon={<PrintIcon />}
            onClick={() => downloadPdf()}
          >
            CETAK
          </Button>
          <table class="styled-table" id="table">
            <thead>
              <tr>
                <th>No. Jual</th>
                <th>Tgl. Jual</th>
                <th>No. Bayar</th>
                <th>Tgl. Bayar</th>
                <th>Pelanggan</th>
                <th>Jenis</th>
                <th>Total Jual</th>
                <th>Total Bayar</th>
              </tr>
            </thead>
            <tbody>
              {pembayaranPiutangs.map((user, index) => (
                <tr key={user.id}>
                  <td>{user.jual.noNotaJual}</td>
                  <td>{user.tanggalJualFormatted}</td>
                  <td>{user.noPembayaranPiutang}</td>
                  <td>{user.tanggalPembayaranPiutangFormatted}</td>
                  <td>{user.jual.pelanggan.namaPelanggan}</td>
                  <td>{user.jenisPembayaranPiutang}</td>
                  <td>{user.totalPembayaranPiutang.toLocaleString("en-US")}</td>
                  <td>{user.jual.totalJual.toLocaleString("en-US")}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div>
        {previewExcel && (
          <Button
            variant="outlined"
            startIcon={<DownloadIcon />}
            onClick={onDownload}
          >
            EXCEL
          </Button>
        )}
        <table class="styled-table" id="table" ref={tableRef}>
          {previewExcel && (
            <tbody>
              <tr>
                <th>No. Jual</th>
                <th>Tgl. Jual</th>
                <th>No. Bayar</th>
                <th>Tgl. Bayar</th>
                <th>Pelanggan</th>
                <th>Jenis</th>
                <th>Total Jual</th>
                <th>Total Bayar</th>
              </tr>
              {pembayaranPiutangs.map((user, index) => (
                <tr key={user.id}>
                  <td>{user.jual.noNotaJual}</td>
                  <td>{user.tanggalJualFormatted}</td>
                  <td>{user.noPembayaranPiutang}</td>
                  <td>{user.tanggalPembayaranPiutangFormatted}</td>
                  <td>{user.jual.pelanggan.namaPelanggan}</td>
                  <td>{user.jenisPembayaranPiutang}</td>
                  <td>{user.totalPembayaranPiutang.toLocaleString("en-US")}</td>
                  <td>{user.jual.totalJual.toLocaleString("en-US")}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
      <Box sx={buttonModifierContainer}>
        <ButtonModifier
          id={id}
          kode={id}
          addLink={`/pembayaranPiutang/tambahPembayaranPiutang`}
          editLink={null}
          deleteUser={deletePembayaranPiutang}
          nameUser={totalPembayaranPiutang}
        />
      </Box>
      {id && (
        <Container>
          <hr />
          <Form>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Bayar :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={tanggalPembayaranPiutang}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No. Bayar :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={noPembayaranPiutang}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No. Jual :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={noNotaJual} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Nama Pelanggan :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={namaPelanggan} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Jenis Transfer :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={jenisPembayaranPiutang}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Total Piutang :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={totalJual} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Total Bayar :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={totalPembayaranPiutang}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Container>
      )}
      <hr />
      <Form onSubmit={searchData}>
        <Box sx={searchBarContainer}>
          <SearchBar value={query} setSearchTerm={setQuery} />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disableElevation
          >
            Cari
          </Button>
        </Box>
      </Form>
      <Box sx={tableContainer}>
        <ShowTablePembayaranPiutang
          currentPosts={pembayaranPiutangsPagination}
        />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Container>
  );
};

export default TampilPembayaranPiutang;

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const downloadButtons = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};
