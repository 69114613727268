import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../../contexts/ContextProvider";
import { Loader } from "../../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Alert,
  Button,
  Snackbar,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SaveIcon from "@mui/icons-material/Save";

const TambahBeli = () => {
  const { screenSize } = useStateContext();
  const { user } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [noNotaBeli, setNoNotaBeli] = useState("");
  const [jenisBeli, setJenisBeli] = useState("");
  const [inputTanggalBeli, setInputTanggalBeli] = useState(new Date());
  const [tempo, setTempo] = useState("");
  const [inputTanggalJatuhTempo, setInputTanggalJatuhTempo] = useState(
    new Date()
  );
  const [kodeSupplier, setKodeSupplier] = useState("");

  const [suppliers, setSuppliers] = useState([]);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const jenisBeliOption = ["TUNAI", "HUTANG"];
  let supplierOptions = suppliers.map((supplier) => ({
    label: `${supplier.kodeSupplier} - ${supplier.namaSupplier}`,
  }));

  useEffect(() => {
    getNextKodeBeli(new Date());
    getSuppliersData();
  }, []);

  const getSuppliersData = async (kodeUnit) => {
    const response = await axios.post(`${tempUrl}/suppliers`, {
      _id: user.id,
      token: user.token,
    });
    setSuppliers(response.data);
  };

  const getNextKodeBeli = async (inputTanggalBeli) => {
    const nextKodeBeli = await axios.post(`${tempUrl}/belisNextKode`, {
      tanggalBeli: inputTanggalBeli,
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setNoNotaBeli(nextKodeBeli.data);
  };

  const saveBeli = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (
      form.checkValidity() &&
      jenisBeli.length !== 0 &&
      kodeSupplier.length !== 0
    ) {
      setLoading(true);
      try {
        setLoading(true);
        let savedBeli = await axios.post(`${tempUrl}/saveBeli`, {
          tanggalBeli: inputTanggalBeli,
          jenisBeli,
          tempo: jenisBeli === "HUTANG" ? tempo : 0,
          tanggalJatuhTempo:
            jenisBeli === "HUTANG" ? inputTanggalJatuhTempo : "",
          tanggalLunas: "",
          lunas: jenisBeli === "HUTANG" ? 0 : 1,
          kodeSupplier: kodeSupplier.split(" -", 1)[0],
          userIdInput: user.id,
          kodeCabang: user.cabang.id,
          _id: user.id,
          token: user.token,
        });
        console.log(savedBeli);
        setLoading(false);
        navigate(`/daftarBeli/beli/${savedBeli.data.id}`);
      } catch (error) {
        alert(error);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  const countDateDuration = (e, value) => {
    let numberOfDaysToAdd =
      e.target.value !== "" ? parseInt(e.target.value) : 0;
    let tempInputTanggalBeli = new Date(inputTanggalBeli);
    let result = tempInputTanggalBeli.setDate(
      tempInputTanggalBeli.getDate() + numberOfDaysToAdd
    );
    let finalDate = new Date(result);

    setInputTanggalJatuhTempo(finalDate);
    setTempo(e.target.value.toUpperCase());
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Transaksi</h3>
      <h5 style={{ fontWeight: 400 }}>Tambah Beli</h5>
      <hr />
      <Card>
        <Card.Header>Beli</Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={saveBeli}>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No. Nota :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={noNotaBeli}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Jenis Beli <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={jenisBeliOption}
                      renderInput={(params) => (
                        <TextField
                          error={error && jenisBeli.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setJenisBeli(value);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Beli <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <DatePicker
                      required
                      dateFormat="dd/MM/yyyy"
                      selected={inputTanggalBeli}
                      customInput={<Form.Control required />}
                      onChange={(date) => {
                        setInputTanggalBeli(date);
                        getNextKodeBeli(date);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tempo (hari) <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    {jenisBeli === "TUNAI" ? (
                      <Form.Control required value={""} disabled readOnly />
                    ) : (
                      <Form.Control
                        required
                        type="number"
                        value={tempo}
                        onChange={(e, value) => countDateDuration(e, value)}
                      />
                    )}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Jt. Tempo :
                  </Form.Label>
                  <Col sm="8">
                    {jenisBeli === "TUNAI" ? (
                      <Form.Control required value={""} disabled readOnly />
                    ) : (
                      <DatePicker
                        required
                        dateFormat="dd/MM/yyyy"
                        selected={inputTanggalJatuhTempo}
                        customInput={<Form.Control required />}
                        disabled
                        readOnly
                      />
                    )}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Supplier <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={supplierOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && kodeSupplier.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setKodeSupplier(value);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Box sx={spacingTop}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate("/daftarBeli")}
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button
                variant="outlined"
                color="success"
                onClick={() => navigate("/daftarBeli/beli/tambahSupplier")}
                sx={{ marginRight: 2 }}
              >
                {"Tambah Supplier"}
              </Button>
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                type="submit"
              >
                Simpan
              </Button>
            </Box>
          </Form>
        </Card.Body>
      </Card>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default TambahBeli;

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const subTitleText = {
  fontWeight: "900",
};

const colorRed = {
  color: "red",
};
